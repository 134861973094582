import React, { useEffect } from "react"
import API from '../../../../api/index'
import Markdown from "../../../../components/HTML/Markdown"
import { useStaticData } from "../../../../context/StaticDataContext"

import { getFullImageUrl } from '../../../../helpers/functions'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Testimonials = () => {

    const { testimonials, setTestmonials } = useStaticData()

    useEffect(() => {
        async function fetchTestimonials() {
            try {
                const req = await API.General.getTestimonial()
                let data = null;
                if (req && req.data) {
                    data = { ...req.data }
                } else throw new Error('Could not fetch TESTIMONIALS')
                if (data) setTestmonials(data)
            } catch (error) {
                console.log('Err : ', error);
            }
        }

        if (!testimonials) fetchTestimonials()
    }, [testimonials, setTestmonials])

    return (
        <div className="relative isolate bg-transparent pt-24 pb-32 sm:pt-32">

            {/* DECORATION  */}
            <div className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl">
                <svg viewBox="0 0 1313 771" aria-hidden="true" className="ml-[max(50%,38rem)] w-[82.0625rem]">
                    <path
                        id="bc169a03-3518-42d4-ab1e-d3eadac65edc"
                        fill="url(#85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3)"
                        d="M360.508 589.796 231.671 770.522 0 498.159l360.508 91.637 232.034-325.485c1.485 150.396 51.235 393.965 238.354 165.069C1064.79 143.261 1002.42-107.094 1171.72 46.97c135.44 123.252 148.51 335.641 138.11 426.428L971.677 339.803l24.062 411.461-635.231-161.468Z"
                    />
                    <defs>
                        <linearGradient
                            id="85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3"
                            x1="1313.17"
                            x2="-88.881"
                            y1=".201"
                            y2="539.417"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#4F46E5" />
                            <stop offset={1} stopColor="#80CAFF" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            {/* DECORATION  */}
            <div className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end">
                <svg
                    viewBox="0 0 1313 771"
                    aria-hidden="true"
                    className="ml-[-22rem] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] xl:ml-0 xl:mr-[calc(50%-12rem)] pointer-events-none"
                >
                    <use href="#bc169a03-3518-42d4-ab1e-d3eadac65edc" />
                </svg>
            </div>

            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-xl text-center">

                    {testimonials
                        ? <h2 className="text-lg font-semibold leading-8 tracking-tight text-green-portal">{testimonials.subtitle}</h2>
                        : <Skeleton baseColor='#414141' className="h-4" width="160px" />}

                    {testimonials
                        ? <p className="mt-2 text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">{testimonials.title}</p>
                        : <Skeleton baseColor='#414141' className="h-8" />}

                </div>
                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-100 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
                    <figure className="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-gray-900/50 sm:shadow-lg sm:ring-1 sm:ring-gray-100/5 xl:col-start-2 xl:row-end-1">
                        <blockquote className="p-12 text-xl font-semibold leading-8 tracking-tight text-gray-100">
                            {testimonials
                                ? <Markdown content={`“${testimonials.featuredTestimonial.body}”`} className="" />
                                : <Skeleton count={5} baseColor='#414141' className="h-4" />}
                        </blockquote>
                        <figcaption className="flex items-center gap-x-4 border-t border-gray-100/10 py-4 px-6">
                            {testimonials && <img
                                className="h-10 w-10 flex-none rounded-full bg-gray-900"
                                src={getFullImageUrl(testimonials.featuredTestimonial.image)}
                                alt=""
                            />}
                            <div className="flex-auto flex justify-between">

                                {testimonials
                                    ? <div className="font-semibold">{testimonials.featuredTestimonial.name}</div>
                                    : <Skeleton baseColor='#414141' width='20px' />}

                                {testimonials
                                    ? <div className="text-gray-300">{`@${testimonials.featuredTestimonial.handle}`}</div>
                                    : <Skeleton baseColor='#414141' width='20px' />}
                            </div>
                            {testimonials && <img className="h-10 w-auto flex-none" src={getFullImageUrl(testimonials.featuredTestimonial.logo)} alt="" />}
                        </figcaption>
                    </figure>
                    {testimonials && testimonials.testimonials.map((columnGroup, columnGroupIdx) => (
                        <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                            {columnGroup.map((column, columnIdx) => (
                                <div
                                    key={columnIdx}
                                    className={classNames(
                                        (columnGroupIdx === 0 && columnIdx === 0) ||
                                            (columnGroupIdx === testimonials.testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                                            ? 'xl:row-span-2'
                                            : 'xl:row-start-1',
                                        'space-y-8'
                                    )}
                                >
                                    {column.map((testimonial) => (
                                        <figure
                                            key={testimonial.handle}
                                            className="rounded-2xl bg-gray-900/50 p-6 shadow-lg ring-1 ring-gray-50/5"
                                        >
                                            <blockquote className="text-gray-50">
                                                <Markdown content={`“${testimonial.body}”`} className="" />
                                            </blockquote>
                                            <figcaption className="mt-6 flex items-center gap-x-4">
                                                <img className="h-10 w-10 rounded-full bg-gray-50" src={getFullImageUrl(testimonial.image)} alt="" />
                                                <div>
                                                    <div className="font-semibold">{testimonial.name}</div>
                                                    <div className="text-gray-300">{`@${testimonial.handle}`}</div>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    ))}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Testimonials 