import React, { memo } from 'react'
import './TypingText.css'

const TypingText = (props) => {

    const { isLast, txt } = props

    return <p className=" typing-demo font-Modeseven text-[.7rem] md:text-[.9rem] my-0.5"
        style={{
            width: `${txt.length}ch`,
            color: 'transparent',
            animation: `txt .5s forwards, typing 1s steps(${txt.length}), blink .5s step-end ${isLast ? 'infinite' : ' 4 '} alternate`
        }}>{txt}</p>
}

export default memo(TypingText);