import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

// THREE JS
import { Effects } from '@react-three/drei'
import { Canvas, extend } from '@react-three/fiber'
import { UnrealBloomPass } from 'three-stdlib'

// Animation
import gsap from 'gsap';

// Context
import { SocketContext } from '../../context/socket';
import { usePrompt } from '../../context/PromptContext'

// Custom Components
import InitializedSessionMenu from './components/threejs/InitializedSessionMenu/InitializedSessionMenu';
import HeroSection from './components/html/HeroSection';
import ThreeTitle from './components/threejs/ThreeTitle';
import Testimonials from './components/html/TestimonialsSection';
import CTASection from './components/html/CTASection/CTASection';
import FAQSection from './components/html/FAQSection';
import Pricing from './components/html/Pricing';
import InviteByEmailPopUp from './components/html/InviteByEmailPopUp';

// Utils
import { degToRad } from 'three/src/math/MathUtils';


extend({ UnrealBloomPass });

const cameraSettings = { fov: 75, near: 0.1, far: 1000, position: [0, 0, 10], aspect: 1 }

const LandingPage = (props) => {

    //========== Navigation
    const navigate = useNavigate()

    //========== Socket from Context
    const socket = useContext(SocketContext);

    //========== Custom Terminal functions
    const { setPrompt, clearTerminal } = usePrompt()

    //========== Refs
    const topRef = useRef(null)
    const qrBoxRef = useRef(null)
    const plansRef = useRef(null)
    const shareLinksRef = useRef(null)
    const menuRef = useRef(null)
    const popUpRef = useRef(null)

    const animateStartLeaving = () => menuRef.current.animateStartLeaving()
    const openMailPopUp = (v) => popUpRef.current.openMailPopUp(v)

    // ======================================================================================
    // ========= UseEffecs

    // Clean localstorage
    useEffect(() => {
        const olHstTk = localStorage.getItem("hstTkn")
        if (olHstTk === 'loading..') {
            localStorage.removeItem("hstTkn");
        }
    }, [])

    // Welcome Prompt 
    useEffect(() => {
        setPrompt(['Loading experiment... Please stand by.'])
        const t = setTimeout(() => {
            setPrompt(['Welcome to RickTransfer', 'Please click START to begin'])
            return clearTimeout(t)
        }, 3000)
    }, [setPrompt])

    // ======================================================================================
    // ========= GSAP ANIMATIONS

    // QRBox & Links leaving animation
    const animateQRBoxAndLinksLeaving = (callback) => {
        if (qrBoxRef && qrBoxRef.current && shareLinksRef && shareLinksRef.current) {
            const TL = gsap.timeline({
                onComplete: () => {
                    callback()
                }
            })
            TL.to(qrBoxRef.current.rotation, { duration: .5, ease: 'power.out', z: degToRad(30) })
            TL.to(shareLinksRef.current.rotation, { duration: .5, ease: 'power.out', z: degToRad(30) }, '<')
            TL.to(qrBoxRef.current.position, { duration: .5, ease: 'power1.out', y: -20 }, '<')
            TL.to(shareLinksRef.current.position, { duration: .5, ease: 'power1.out', y: -20 }, '<')
        }
    }

    const scrollUpThenStart = () => {

        topRef.current.click()
        const timer = setTimeout(() => {
            animateStartLeaving()
        }, 1200);

        return () => {
            clearTimeout(timer);
        };

    }

    return <div id="top" className='w-full h-full opacityFadeIn'>

        {/* Usefull to be toggle from THREEJS Canvas */}
        <a ref={plansRef} href='#plans' className='hidden anchor'>plans</a>
        <Link ref={topRef} to={'/#top'} className='hidden anchor'>top</Link>

        <InviteByEmailPopUp ref={popUpRef} />

        {/* THREE JS  */}
        <div className='flex justify-center items-center w-auto mx-auto h-screen relative '>

            {/* THREE JS SCENE*/}
            <Canvas onClick={animateStartLeaving} className='cursor-pointer' camera={cameraSettings} >

                {/* =============================================== Ligth ==========================================*/}
                {/* <ambientLight intensity={.06} />
                <spotLight intensity={1} castShadow color={"#FFF"} penumbra={1} position={[5, 20, 10]} />
                <spotLight intensity={1} castShadow color={"#FFF"} penumbra={1} position={[-5, 25, -8]} /> */}
                <ambientLight intensity={.2} />
                <spotLight intensity={1} castShadow color={"#000"} penumbra={1} position={[5, 20, 10]} />
                <spotLight intensity={2} castShadow color={"green"} penumbra={1} position={[5, 15, 10]} />
                <spotLight intensity={1.2} castShadow color={"#00e600"} penumbra={1} position={[5, 15, 10]} />
                <spotLight intensity={1} castShadow color={"red"} penumbra={1} position={[-7, 10, 12]} />

                {/* =============================================== Effects UnrealBloomPass ==========================================*/}
                <Effects disableGamma>
                    <unrealBloomPass threshold={0.1} strength={.3} radius={0.1} />
                </Effects>

                <ThreeTitle />

                <InitializedSessionMenu
                    ref={menuRef}
                    socket={socket}
                    qrBoxRef={qrBoxRef}
                    degToRad={degToRad}
                    navigate={navigate}
                    setPrompt={setPrompt}
                    clearTerminal={clearTerminal}
                    shareLinksRef={shareLinksRef}
                    openMailPopUp={(v) => openMailPopUp(v)}
                    animateQRBoxAndLinksLeaving={animateQRBoxAndLinksLeaving}
                />

            </Canvas>

        </div>

        {/* HTML */}
        <div className='sm:mx-auto w-full sm:w-11/12 lg:w-5/6 xxl:w-9/12 -mt-28'>

            <HeroSection scrollUpThenStart={scrollUpThenStart} />
            <Testimonials />
            <CTASection scrollUpThenStart={scrollUpThenStart} />
            {/* <Pricing /> */}
            <FAQSection />

        </div>
    </div>

}



export default LandingPage;