import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { useThree } from "@react-three/fiber";
import { degToRad } from "three/src/math/MathUtils";

import gsap from "gsap";
import toast from "react-hot-toast";

import OrAnimatedText from "./components/OrAnimatedText";
import ShareLinks from "./components/ShareLinks";
import QRBOx from "./components/QRBox";
import CheckPlansCTA from "./components/CheckPlanCTA";
import StartCTA from "./components/StartCTA";

import API from '../../../../../api/index'


const desktopSetting = {
    mode: 'desktop',
    qr: { pos: [-7, 0, 0], rot: [0, degToRad(22), 0] },
    links: { pos: [7, 0, 0], rot: [0, degToRad(-20), 0] },

}

const mobileSetting = {
    mode: 'mobile',
    qr: { pos: [0, 2, 0], rot: [0, 0, 0] },
    links: { pos: [0, -6, 0], rot: [0, 0, 0] },
}

const InitializedSessionMenu = forwardRef((props, ref) => {

    const { openMailPopUp, qrBoxRef, shareLinksRef, animateQRBoxAndLinksLeaving, navigate, setPrompt, clearTerminal, socket } = props

    const { viewport } = useThree()

    const startRef = useRef(null)

    //========== States
    const [sessionInit, setSessionInit] = useState(null)
    // const [limitDetails, setLimitDetails] = useState(false)
    const [positionSettings, setPositionSettings] = useState(viewport.width < 17 ? mobileSetting : desktopSetting)

    useImperativeHandle(ref, () => ({
        animateStartLeaving() {
            animateStartLeaving()
        },
    }));

    // EFFECTS
    // Handle responsive row/col
    useEffect(() => {

        const animateToNewPos = (ref, position, rotation) => {
            if (ref && ref.current) {
                const introTl = gsap.timeline();
                introTl.to(ref.current.position, { duration: 1, ease: "power4.out", x: position[0], y: position[1], z: position[2] })
                introTl.to(ref.current.rotation, { duration: 1, ease: "power4.out", x: rotation[0], y: rotation[1], z: rotation[2] }, '<')
            }
        }
        const handleResize = () => {
            if (viewport.width < 17 && positionSettings.mode === 'desktop') {
                animateToNewPos(qrBoxRef, mobileSetting.qr.pos, mobileSetting.qr.rot)
                animateToNewPos(shareLinksRef, mobileSetting.links.pos, mobileSetting.links.rot)
                setPositionSettings(mobileSetting)
            } else if (viewport.width >= 17 && positionSettings.mode === 'mobile') {
                animateToNewPos(qrBoxRef, desktopSetting.qr.pos, desktopSetting.qr.rot)
                animateToNewPos(shareLinksRef, desktopSetting.links.pos, desktopSetting.links.rot)
                setPositionSettings(desktopSetting)
            }
        }

        window.addEventListener('resize', handleResize)
        handleResize()

        return () => window.removeEventListener('resize', handleResize)
    }, [viewport, positionSettings, qrBoxRef, shareLinksRef])

    // Dimension Generated Prompt
    useEffect(() => {
        if (sessionInit) {
            clearTerminal()
            setPrompt([`Dimension ${sessionInit.slug} ready`, 'SCAN or SHARE invitation link', 'Waiting for a 2nd device..'])
        }
    }, [sessionInit, clearTerminal, setPrompt])

    // Socket.io Setup
    useEffect(() => {
        if (sessionInit && sessionInit.slug) {
            socket.on('session_linked', (data, err) => {
                if (data && data.slug && data.slug === sessionInit.slug) {
                    animateQRBoxAndLinksLeaving(() => {
                        return navigate(`/dimension/${sessionInit.slug}`);
                    })
                } else if (err) return toast.error(err)
            });
        }
    }, [sessionInit, socket, navigate, animateQRBoxAndLinksLeaving])


    // ======================================================================================
    // ========= Dimension initialization
    // check for hstTkn in Storage then ask for validity before saving session or ask to create a new one
    const initSession = async () => {

        // oldHostToken
        const olHstTk = localStorage.getItem("hstTkn")

        if (olHstTk === 'loading..' || sessionInit !== null) {
            return 0;
        } else {
            localStorage.removeItem("gstTkn");
        }

        const catchHandler = (err) => {
            if (err.data && err.data.error && err.data.error.message && err.data.error.details) {
                const allowed = err.data.error.details.allowed
                if (!allowed) {
                    setPrompt([err.data.error.message])
                }
                // toast.error(err.data.error.message)
            }
            if (localStorage.getItem("hstTkn") === 'loading..') localStorage.removeItem("hstTkn");
        }

        if (!olHstTk) {
            // ad user id here
            localStorage.setItem("hstTkn", 'loading..')

            API.Session.init({})
                .then(res => {

                    const data = res && res.data ? res.data : null;

                    if (data) {

                        localStorage.setItem("hstTkn", data.hostToken)
                        socket.emit('session_initialized', { slug: data.slug, tkn: data.hostToken }, (error) => {
                            if (error) alert(error)
                        });
                        setSessionInit(data)

                    } else return toast.error('Opps.. something wrong')

                })
                .catch((err) => {
                    catchHandler(err)
                    animateStartComeBack()
                })

        } else {

            localStorage.setItem("hstTkn", 'loading..')

            // check if session still alive
            API.Session.checkTokenInfo({ tkn: olHstTk }).then(res => {

                const data = res.data

                if (data.valid) {

                    localStorage.setItem("hstTkn", olHstTk)
                    socket.emit('session_initialized', { slug: data.session.slug, tkn: olHstTk }, (error) => {
                        if (error) alert(error)
                    });

                    setSessionInit(data.session)

                } else {
                    if (localStorage.getItem("hstTkn") === 'loading..') localStorage.removeItem("hstTkn");
                    initSession();
                }
            }).catch(error => {
                console.log('Err : ', error);
                catchHandler(error)
                initSession();
            })
        }
    }

    // StartCallToAction leaving animation
    const animateStartLeaving = () => {
        if (startRef && startRef.current) {
            const TL = gsap.timeline({ onComplete: () => { } })
            TL.to(startRef.current.position, { duration: .8, ease: 'power2.out', y: -20 })
            TL.to(startRef.current.scale, { duration: .8, ease: "power2.out", x: 0, y: 0, z: 0 }, '<')
            initSession()
        }
    }

    // StartCallToAction comeback animation
    const animateStartComeBack = () => {
        if (startRef && startRef.current) {
            const TL = gsap.timeline()

            TL.to(startRef.current.position, { duration: .5, ease: 'power2.out', z: 0 })
            TL.to(startRef.current.scale, { duration: .5, ease: "power2.out", x: 1, y: 1, z: 1 }, '<')

        }
    }

    return (
        <group>
            {/* {limitDetails && !limitDetails.allowed && <CheckPlansCTA dailyPerc={limitDetails.dailyPerc} monthlyPerc={limitDetails.monthlyPerc} IP={limitDetails.IP} />} */}

            {/* INIT DIMENTION CTA  */}
            {!sessionInit && <mesh onClick={() => animateStartLeaving()} ref={startRef} >
                <StartCTA />
            </mesh>}

            {sessionInit &&
                <group
                    scale={
                        (viewport.width /
                            (positionSettings.mode === 'mobile'
                                ? (viewport.width < 13.2 ? 12 : 15)
                                : (viewport.width < 20 ? 20 : viewport.width)
                            )
                        )
                    }
                    position={[0, viewport.width < 20 ? 1 : -.5, -3]}
                >

                    <mesh rotation={positionSettings.qr.rot} position={positionSettings.qr.pos} ref={qrBoxRef} onPointerOver={() => document.body.style.cursor = 'pointer'} onPointerOut={() => document.body.style.cursor = 'auto'}>
                        <QRBOx url={sessionInit.sessionUrl} scale={5} qrcode={sessionInit.qrcode} />
                    </mesh>

                    {/* <OrAnimatedText /> */}

                    <mesh ref={shareLinksRef} rotation={positionSettings.links.rot} position={positionSettings.links.pos} onPointerOver={() => document.body.style.cursor = 'pointer'} onPointerOut={() => document.body.style.cursor = 'auto'}>
                        <ShareLinks openMailPopUp={() => openMailPopUp(sessionInit.sessionUrl)} mode={positionSettings.mode} url={sessionInit.sessionUrl} />
                    </mesh>

                </group>
            }
        </group>

    )

})

export default InitializedSessionMenu;