import React, { useEffect, useMemo, useRef } from 'react'

import { extend } from '@react-three/fiber'

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'

import defaultFont from '../../fonts/Montserrat_SemiBold_Regular.json'

import { Vector3 } from 'three'
import { useState } from 'react'


const TextCustom = (props) => {

    const { font, text, color, params, position, rotation } = props

    const fontLoaded = new FontLoader().parse(font ? font : defaultFont);

    const matRef = useRef()

    const [centeredPos, setCenteredPos] = useState()

    extend({ TextGeometry });

    const config = useMemo(
        () => ({ font: fontLoaded, ...params }),
        [fontLoaded, params]
    )

    useEffect(() => {
        if (matRef.current && !centeredPos) {
            const _offset = new Vector3();
            matRef.current.computeBoundingBox();
            matRef.current.boundingBox.getCenter(_offset).negate();
            setCenteredPos(_offset)
        }
    }, [])

    return <group rotation={rotation} position={position}>
        <mesh position={centeredPos}>
            <textGeometry ref={matRef} attach="geometry" args={[text, config]} />
            {color ?
                <meshLambertMaterial
                    attach="material"
                    color={color}
                    fog={true}
                />
                : <meshNormalMaterial attach="material" />}
        </mesh>
    </group>

}

export default TextCustom