import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const Markdown = (props) => {

    const { className, content } = props

    return <ReactMarkdown
        components={{
            p: ({ node, ...props }) => (
                <p
                    className={className}
                    {...props}
                />
            ),
        }}
    >
        {content}
    </ReactMarkdown>
}

export default Markdown