import React, { forwardRef, useImperativeHandle, useState, Fragment, useRef, } from "react";
import { Dialog, Transition } from '@headlessui/react'

import API from '../../../../api/index'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { usePrompt } from "../../../../context/PromptContext";
import { useAuth } from "../../../../context/AuthContext";
import Spinner from "../../../../components/HTML/Spinner";


const InviteByEmailPopUp = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        openMailPopUp(v) {
            openMailPopUp(v)
        }
    }));

    const [link, setLink] = useState()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const { setPrompt } = usePrompt()
    const { userInfo } = useAuth()

    const cancelButtonRef = useRef(null)

    const openMailPopUp = (url) => {
        setLink(url)
        setOpen(true)
    }

    const iniviteMail = (e) => {
        e.preventDefault()
        setLoading(true)

        const valuesArr = new Array(...e.target)
        const email = valuesArr[0].value

        const data = {
            link: link,
            to: email,
            hostName: userInfo ? userInfo.username : null
        }

        API.Session.inviteByEmail(data).then(res => {
            if (res.status === 200) {
                setPrompt([`Invitation sent to ${email}`])
                toast.success('Email sent !')
                setOpen(false)
                setLoading(false)
            }
        }).catch(err => {
            toast.error(err)
            setLoading(false)
        })
    }


    return <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-400 delay-200"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-gray-900 px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div className="mx-auto flex h-24 w-24 items-center justify-center rounded-full !shadow-none">
                                <img src="/img/logo192.png" alt="logo" />
                            </div>
                            <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl mt-4">
                                Invite to share files
                            </h2>
                            <form onSubmit={e => iniviteMail(e)} className="mx-auto mt-10 flex max-w-md gap-x-4">
                                <label htmlFor="email-address" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                                    placeholder="Enter email"
                                />
                                <button
                                    type="submit"
                                    className="flex items-center justify-center rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                >
                                    {loading ? <Spinner /> : <FontAwesomeIcon className="mr-1" icon={faPaperPlane} />} Send
                                </button>
                            </form>
                            <svg
                                viewBox="0 0 1024 1024"
                                className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
                                aria-hidden="true"
                            >
                                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                                <defs>
                                    <radialGradient
                                        id="759c1415-0410-454c-8f7c-9a820de03641"
                                        cx={0}
                                        cy={0}
                                        r={1}
                                        gradientUnits="userSpaceOnUse"
                                        gradientTransform="translate(512 512) rotate(90) scale(512)"
                                    >
                                        <stop stopColor="#5FBD25" />
                                        <stop offset={1} stopColor="#5FBD25" stopOpacity={0} />
                                    </radialGradient>
                                </defs>
                            </svg>

                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition.Root>
})

export default InviteByEmailPopUp