import React, { useRef } from "react";

import { Float } from "@react-three/drei";
import * as THREE from 'three'
import gsap from "gsap";
import { useLoader } from "react-three-fiber";

import TextCustom from "../../../../../../components/THREEJS/TextCustom";

import { degToRad } from "three/src/math/MathUtils";


const InviteEmail = (props) => {

    const { openMailPopUp } = props

    const emailRef = useRef()

    const texture = useLoader(THREE.TextureLoader, '/img/email-12-512.png')

    const animateOnClick = () => {
        if (emailRef.current) {

            openMailPopUp()

            const boxTl = gsap.timeline();

            const coord = { x: 0, y: degToRad(360), z: 0 }
            const base = { x: degToRad(0), y: degToRad(0), z: degToRad(0) }

            boxTl.to(emailRef.current.rotation, { duration: .6, ease: "power4.out", ...coord })
            boxTl.to(emailRef.current.rotation, { duration: .4, ease: "power4.in", ...base }, '>')
        }
    }

    return <Float floatIntensity={5} rotationIntensity={0} speed={2}>
        <group onClick={() => animateOnClick()}>

            {/* EMAIL ICON */}
            <mesh ref={emailRef} position={[0, 6, 0]} scale={1}>
                <planeBufferGeometry attach={"geometry"} args={[10, 10, 10]} />
                <meshPhongMaterial attach={"material"} color={"white"} map={texture} transparent={true} emissive={"white"} opacity={1} emissiveIntensity={.01} />
            </mesh>

            {/* TEXT */}
            <group>
                <TextCustom color={"#ffffff"} position={[0, -.6, .2]} text={'invite'} params={{ size: 1.2, height: .10, curveSegments: 64, }} />
                <TextCustom color={"#ffffff"} position={[0, -2.2, .2]} text={'by email'} params={{ size: 1.1, height: .10, curveSegments: 64, }} />
            </group>

        </group>
    </Float>

}

export default InviteEmail;