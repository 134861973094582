import React, { useCallback, useEffect, useRef, useState } from 'react';

//THREE JS
import { Sphere, MeshDistortMaterial } from '@react-three/drei'

// Animation
import gsap from 'gsap'

// Models
import { PortalGun3DModel } from '../../../components/3DModels/PortalGun3DModel'

// Utils
import { degToRad } from 'three/src/math/MathUtils';


const PortalGunAnimated = (props) => {

    const { animateOn } = props

    const gunRef = useRef(null)
    const dropRef = useRef(null)

    const [animate, setAnimate] = useState(false)

    const handleAnimation = useCallback(() => {

        setAnimate(true)

        const dropTl = gsap.timeline();

        dropTl.to(dropRef.current.position, { delay: .6, y: .25, duration: 1 });
        if (animateOn) {
            dropTl.to(dropRef.current.position, { duration: 1, ease: "power1.out", x: -5, y: 5.5, z: -200 }, '>+.5')
            dropTl.to(dropRef.current.scale, { duration: .1, ease: "power2.out", x: 0, y: 0, z: 0 }, '>')
            dropTl.to(dropRef.current.position, { duration: .1, ease: "power2.out", x: 0, y: .25, z: -.22 }, '>.2')
            dropTl.to(dropRef.current.scale, { duration: .5, ease: "power2.out", x: .1, y: .1, z: .1 }, '>')
        }


        const gunTl = gsap.timeline();

        gunTl.to(gunRef.current.position, { delay: .6, y: 0, duration: 1 });
        gunTl.to(gunRef.current.rotation, { x: degToRad(20), duration: .2 }, '>+.5');
        if (animateOn) {
            gunTl.to(gunRef.current.position, { z: .4, duration: .2 }, '<');
            gunTl.to(gunRef.current.position, { y: .1, duration: .2 }, '<');
            gunTl.to(gunRef.current.position, { z: 0, duration: .8 }, '>');
            gunTl.to(gunRef.current.position, { y: 0, duration: .8 }, '<');
            gunTl.to(gunRef.current.rotation, { x: degToRad(5), duration: .8 }, '<');
            gunTl.to(gunRef.current.position, { y: -5, duration: 1 }, '>+2');
            gunTl.to(dropRef.current.position, { y: -4.75, duration: 1 }, '<');
        }

    }, [animateOn])

    useEffect(() => {
        if (!animate)
            handleAnimation()
    }, [animate, handleAnimation])

    return (
        <mesh {...props}>
            <Sphere ref={dropRef} position={[0, -4.75, -.15]} scale={.09} visible args={[1, 100, 200]} >
                <MeshDistortMaterial emissive={"#26ff00"} emissiveIntensity={1} color="#55ff00" attach="material" distort={.7} speed={10} />
            </Sphere>
            <mesh onClick={handleAnimation} position={[0, -4, 0]} ref={gunRef} geometry-translate={[2, 3, 15]}>
                <PortalGun3DModel castShadow
                    rotation={[degToRad(5), -degToRad(90), 0]} scale={.005} />
            </mesh>
        </mesh>
    )
};

export default PortalGunAnimated;
