import React, { useMemo, useContext, useState } from 'react'

export const StaticData = React.createContext();

export const useStaticData = () => {
    return useContext(StaticData);
}

export const StaticDataProvider = ({ children }) => {

    const [faq, setFaq] = useState(null)
    const [service, setService] = useState(null)
    const [plans, setPlans] = useState()
    const [pricing, setPricing] = useState()
    const [testimonials, setTestmonials] = useState(null)
    const [logoCloud, setLogoCloud] = useState(null)
    const [stats, setStats] = useState(null)
    const [contactus, setContactUs] = useState()
    const [topics, setTopics] = useState([])
    const [about, setAbout] = useState(null)
    const [profileInfo, setProfileInfo] = useState(null)
    const [privacyPolicy, setPrivacyPolicy] = useState(null)
    const [termsOfuses, setTermsOfuses] = useState(null)

    const context = useMemo(() => {
        return {
            faq,
            setFaq,
            service,
            setService,
            plans,
            setPlans,
            pricing,
            setPricing,
            testimonials,
            setTestmonials,
            logoCloud,
            setLogoCloud,
            stats,
            setStats,
            contactus,
            setContactUs,
            topics,
            setTopics,
            about,
            setAbout,
            profileInfo,
            setProfileInfo,
            privacyPolicy,
            setPrivacyPolicy,
            termsOfuses,
            setTermsOfuses
        };
    }, [
        faq,
        service,
        plans,
        pricing,
        testimonials,
        logoCloud,
        stats,
        contactus,
        topics,
        about,
        profileInfo,
        privacyPolicy,
        termsOfuses
    ]);

    return (
        <StaticData.Provider value={context}>{children}</StaticData.Provider>
    );
};