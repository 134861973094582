import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import API from '../api/index'
import SignUp from '../pages/Authentification/components/SignUp'
const PaymentSuccessPage = () => {


    const [searchParams, setSearchParams] = useSearchParams();

    const [email, setEmail] = useState(searchParams.get("email"));
    const [key, setkey] = useState(searchParams.get("key"));
    const [orId, setorId] = useState(searchParams.get("orId"));

    const [needSignUp, setNeedSignUp] = useState(false);


    useEffect(() => {

        if (email && key && orId) {
            setSearchParams({})

            API.Checkout.confirmPayment({ email, key, orId }).then(res => {
                if (res && res.data && res.data.emailToSignUp && email === res.data.emailToSignUp) {
                    setNeedSignUp(true)
                }
            }).catch(err => {
                console.log('err', err);
            })

        }
    }, [searchParams])

    return (

        <div
            className={(needSignUp ? " grid " : " flex justify-center items-center ") + " relative h-screen mx-automax-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 py-8 md:py-12"}
        >


            {needSignUp && <section
                aria-labelledby="summary-heading"
                className=" pt-6 pb-6 text-indigo-300 px-6 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pt-0 lg:pb-24"
            >
                <SignUp prefilledEmail={email} showLogin={false} />
            </section>}

            <section
                aria-labelledby="payment-heading"
                className={(needSignUp ? "lg:col-start-1 lg:row-start-1" : "") + " py-16 px-4 flex flex-col justify-center  lg:mx-auto lg:w-full lg:max-w-lg lg:pt-0 lg:pb-24"}
            >
                <div className="text-center">
                    <p className="text-base font-semibold text-green-portal">Payment successful</p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-50 sm:text-5xl">Thanks for you purchase !</h1>
                    {needSignUp && <p className="mt-10 text-base leading-7 text-gray-200">Please Sign Up now to enjoy your plan's privileges</p>}
                    <div className="mt-4 flex items-center justify-center gap-x-6">
                        {!needSignUp &&
                            <NavLink to={"/"} className="text-sm font-semibold text-gray-50 mt-6">
                                <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
                                Back Home
                            </NavLink>
                        }
                    </div>
                </div>
            </section>

        </div>
        // <div className="grid h-full place-items-center  py-24 px-6 sm:py-32 lg:px-8">
        //     <div className="text-center">
        //         <p className="text-base font-semibold text-green-portal">Payment successful</p>
        //         <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-50 sm:text-5xl">Thanks for you purchase !</h1>
        //         {needSignUp && <p className="mt-10 text-base leading-7 text-gray-200">Please Sign Up now to enjoy your plan's privileges</p>}
        //         <div className="mt-4 flex items-center justify-center gap-x-6">
        //             {needSignUp
        //                 ? <NavLink
        //                     to={`/authentification/register?email=${email}`}
        //                     className="rounded-md bg-green-portal px-3.5 py-2.5 text-sm font-semibold text-gray-200 shadow-sm hover:bg-green-portal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
        //                 >
        //                     Sign Up Now
        //                 </NavLink>
        //                 : <NavLink to={"/"} className="text-sm font-semibold text-gray-50 mt-6">
        //                     <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
        //                     Back Home
        //                 </NavLink>
        //             }
        //         </div>
        //     </div>

        // </div>
    )
}

export default PaymentSuccessPage;