import React, { useMemo, useContext, useState, useEffect } from 'react'
import API from '../api/index'
import axios from 'axios'
import { usePrompt } from './PromptContext';
import { useStaticData } from './StaticDataContext';

export const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthContextProvider = ({ children }) => {

    const [userInfo, setUserInfo] = useState(null)
    const { setPrompt } = usePrompt()
    const { setProfileInfo } = useStaticData()


    useEffect(() => {
        const getUserDataFromToken = async () => {
            if (!userInfo || (userInfo && userInfo.plan === undefined)) {
                const tkn = localStorage.getItem("tkn")
                await API.Account.getTknInfo({ tkn }).then(res => {
                    if (res && res.data && res.data.id) {
                        setUserInfo(res.data)
                    } else {
                        localStorage.removeItem("loggedUser")
                        localStorage.removeItem("tkn")
                        setUserInfo(null)
                    }
                })
            }
        }
        getUserDataFromToken()
    }, [userInfo])

    const logout = () => {
        window.localStorage.removeItem("tkn");
        window.localStorage.removeItem("loggedUser");
        delete axios.defaults.headers.common["Authorization"];
        setPrompt(['Disconnected.. see you soon !'])
        setProfileInfo(null)
        setUserInfo(null)
    }

    const context = useMemo(() => {
        return {
            userInfo,
            setUserInfo,
            logout
        };
    }, [userInfo, setUserInfo, logout]);

    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    );
};