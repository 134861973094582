import React, { useMemo, useContext } from 'react'
export const PromptContext = React.createContext();

export const usePrompt = () => {
    return useContext(PromptContext);
}

export const PromptContextProvider = ({ clearTerminal, setPrompt, initDimensionTimer, children }) => {
    const context = useMemo(() => {
        return {
            setPrompt,
            clearTerminal, initDimensionTimer
        };
    }, [setPrompt, clearTerminal, initDimensionTimer]);

    return (
        <PromptContext.Provider value={context}>{children}</PromptContext.Provider>
    );
};