import React, { createRef, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'

import fileDownload from 'js-file-download';
import toast from 'react-hot-toast';
import gsap from 'gsap';

import API from '../../../api/index'

import { degToRad } from 'three/src/math/MathUtils';

import { useThree } from '@react-three/fiber';
import FileItem from '../../../components/FileItem';

const FilesInterceptor = forwardRef((props, ref) => {

    const { sessionAccess, setPrompt, socket, waitingFiles, incrementUsedMO } = props

    const [receivedFiles, setReceivedFiles] = useState([])
    const [socketEventOn, setSocketEventOn] = useState(false)
    const [focusedFileIndex, setFocusedFileIndex] = useState(0)

    const myRefs = useRef([]);

    const { viewport } = useThree()

    let dl = false

    // retreive navFile function from Parent
    useImperativeHandle(ref, () => ({
        navFiles(val) {
            navFiles(val)
        },
        dowloadFileHandler(val) {
            dowloadFileHandler(val)
        },
    }));

    const shortName = (name) => {
        if (name) {
            let newName = []
            const nameArr = name.split('')
            nameArr.forEach((ch, i) => {
                if (i >= 14) return 0
                else newName.push(ch)
            })
            if (nameArr.length > 15) newName.push('..')
            return newName.join('')
        } else return 'N/a'
    }

    // UseEffects ====================================================================================

    // waiting for portal to open and animate file pop out
    useEffect(() => {

        const handleWaitinFiles = (wF) => {
            setFocusedFileIndex(wF.length - 1);
            const wFWithInit = wF.map((el, i) => {
                if (myRefs.current[i] === undefined) myRefs.current[i] = createRef()
                return { ...el, initAnimDone: false }
            })
            setReceivedFiles([...wFWithInit]);
        }

        if (waitingFiles && waitingFiles.length !== 0) {
            setTimeout(() => {
                handleWaitinFiles(waitingFiles);
            }, 3800)
        }

    }, [waitingFiles])

    // Handle file position update and NavFile updates
    useEffect(() => {

        // LOOP through fileList and update position of each element using myRefs.current[i] 
        const navAnimation = (fileListLength, focusedFile) => {

            for (let i = 0; i < fileListLength; i++) {

                const ref = myRefs.current[i]
                const newTL = gsap.timeline()

                // Define new positions
                const newX = focusedFile === i ? 0 : -((focusedFile - i) * 4)
                const newY = focusedFile === i ? -8 : -12
                const newZ = focusedFile === i ? 10 : 2

                // Update positions
                newTL.to(ref.current.rotation, { duration: .2, ease: "power4.out", x: 0 })
                newTL.to(ref.current.scale, { duration: .2, ease: "power4.out", x: .8, y: .8, z: .8 }, '<')
                newTL.to(ref.current.position, { duration: .4, ease: "power4.out", x: newX, y: newY, z: newZ }, '<')
            }

        }

        navAnimation(receivedFiles.length, focusedFileIndex)
    })

    // socket listner for new files => update receivedFiles & push new Ref
    useEffect(() => {

        const addFilehandler = (data) => {

            const pushFile = (newObj) => {
                setReceivedFiles(receivedFiles => {
                    if (receivedFiles.findIndex(el => el.id === newObj.id) === -1) return [...receivedFiles, newObj]
                    else return receivedFiles
                });
            }

            if (sessionAccess) {
                // incrementUsedMO(data.file)
                pushFile({ ...data, initAnimDone: false })
                myRefs.current.push(createRef())
            }

        }

        if (!socketEventOn) {

            socket.on('new_session_item', (data, err) => {
                if (data && data.file) {
                    addFilehandler(data)
                }
            });

            setSocketEventOn(true)

        }

    }, [socketEventOn, socket, incrementUsedMO, sessionAccess])


    useEffect(() => {
        socket.removeAllListeners("session_item_update_request");
        socket.on('session_item_update_request', (data, err) => {
            const receivedFilesIdsList = receivedFiles.map(f => f.id)
            socket.emit('session_item_update_reply', { ids: receivedFilesIdsList }, (error) => { console.log('session_item_update_reply err', error) });
        });
    }, [receivedFiles])


    // GSAP ANIMATIONS ====================================================================================

    // animation for file downloaded then move to nextBaseRef with scale of .1
    const dlFileAnimation = (ref, callback) => {

        if (ref.current) {
            const dropTL = gsap.timeline({
                onComplete: () => {
                    callback()
                }
            })

            dropTL.to(ref.current.position, { duration: 1, ease: 'power1.out', y: -10, x: -5 })
            dropTL.to(ref.current.scale, { duration: 1, ease: 'power1.out', x: 0, y: 0, z: 0 }, '<')
        }

    }


    // HANDLING FILES ACTIONS ====================================================================================

    //  Check for context before update focusedFileIndex depending on received val (1 or -1)
    const navFiles = (val) => {
        if (dl) return
        if (receivedFiles.length <= 1 || (focusedFileIndex === (receivedFiles.length - 1) && val === 1) || (focusedFileIndex === 0 && val === -1)) {
            return
        } else {
            const newIndx = focusedFileIndex + val
            setFocusedFileIndex(newIndx)
        }
    }

    // Get currentRef and nextBaseRef => API.Media.getFile => call next func (dlAnim,removeItemAPI,removeItemFromList) => then fileDownload
    const handleBoxClick = (i) => {

        if (i === focusedFileIndex) {
            dowloadFileHandler()
        }

    }

    const dowloadFileHandler = () => {
        if (dl) return

        const file = receivedFiles[focusedFileIndex]

        if (file) {

            const currRef = myRefs.current[focusedFileIndex]

            dl = true
            setPrompt([`Downloading..`])

            // download file Handler
            API.Media.getFile(file.file.url).then(res => {
                if (res && res.data) {
                    dlFileAnimation(currRef, () => {
                        removeItemAPI(file)
                        removeItemFromList(file.id, focusedFileIndex)
                        dl = false
                        setPrompt([`File downloaded`])
                    })
                    fileDownload(res.data, file.file.name)

                } else setPrompt([`ERROR can't download ${shortName(file.file.name)}`])
            }).catch((err) => {
                dl = false
                toast.error(`Error while downloading file.. : ${err}`)
            })

        }
    }

    // create array without element corresponding to ID and update focusedFileIndex & receivedFiles
    const removeItemFromList = (id) => {

        const list = [...receivedFiles]
        const newList = list.filter(el => el.id !== id)
        const newIndx = (focusedFileIndex !== 0 && focusedFileIndex === newList.length) ? focusedFileIndex - 1 : focusedFileIndex

        setFocusedFileIndex(newIndx)
        setReceivedFiles(newList)

    }

    // Call deleteSessionItemWithFiles with item and file id 
    const removeItemAPI = (file) => {
        return API.SessionItems.deleteSessionItemWithFiles(file.id, file.file.id).catch(err => {
            toast.error(`Couldn't delete file.. ${err}`)
        })
    }

    return <group position={[0, viewport.width < 13 ? 2 : 0, 0]} scale={viewport.width < 8 ? viewport.width / 8 : 1} className='flex flex-row justify-around items-center'>
        {receivedFiles.map((data, i) => {
            return <mesh key={data.id} ref={myRefs.current[i]} rotation={[-degToRad(60), 0, 0]} scale={[0.5, 0.5, 0.5]} position={[0, 0, -2]} onClick={() => handleBoxClick(i)}>
                <FileItemMemo
                    name={data.name} ext={data.file.ext} url={data.file.formats ? data.file.formats.thumbnail.url : data.file.url} />
            </mesh>
        })}
        <Hints receivedFiles={receivedFiles} setPrompt={setPrompt} />
    </group>
})

const FileItemMemo = memo((props) => {
    return <FileItem {...props} />
})

const Hints = (props) => {

    const { receivedFiles, setPrompt } = props

    const baseHints = {
        downloadFile: false,
        navFiles: false
    }
    const [hints, setHints] = useState({ ...baseHints })

    useEffect(() => {

        if (receivedFiles && receivedFiles.length > 0 && !hints.downloadFile) {
            setPrompt(['💡 press ENTER or clic file to download'])
            setHints(h => {
                const newHints = { ...h }
                newHints.downloadFile = true
                return newHints
            })
        }

        if (receivedFiles && receivedFiles.length > 1 && !hints.navFiles) {
            setPrompt(['💡 use directional arrows or swipe to navigate'])
            setHints(h => {
                const newHints = { ...h }
                newHints.navFiles = true
                return newHints
            })
        }
    }, [receivedFiles])

    return <mesh></mesh>
}


export default FilesInterceptor;