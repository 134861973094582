import { faCheck, faChevronDown, faChevronUp, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, RadioGroup } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from 'react-router-dom';

import API from '../api/index'
import Markdown from "../components/HTML/Markdown";
import OverlayComponent from "../components/HTML/OverlayComponent/OverlayComponent";
import Spinner from "../components/HTML/Spinner";
import { useAuth } from "../context/AuthContext";
import { loadStripe } from '@stripe/stripe-js';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51Mhyt3ENSYaZLGqQRGELRUExIeE3GtoFZBiyan94HYxAmtwyonYOXsEppND9BbuM8h4DrKgmRD1GCX6b8Sjbamkm00zVw2rVdy');

const products = [
    {
        id: 1,
        name: 'High Wall Tote',
        href: '#',
        price: '$210.00',
        color: 'White and black',
        size: '15L',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/checkout-page-07-product-01.jpg',
        imageAlt: 'Front of zip tote bag with white canvas, white handles, and black drawstring top.',
    },
    // More products...
]

const featuresKeys = [
    { key: 'sessionPerDay', label: ' linkup / day' },
    { key: 'sessionPerMonth', label: ' linkup / month' },
    { key: 'maxMOPerSession', label: ' MO / linkup' },
    { key: 'sessionInitTokenExpireIn', label: ' min / linkup' }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const CheckOutPage = () => {

    const { userInfo } = useAuth()

    const { planId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [email, setEmail] = useState('')
    const [emailConfirmation, setEmailConfirmation] = useState('')

    const [plan, setPlan] = useState(null)
    const [freq, setFreq] = useState(searchParams.get("freq"));
    const [showLoginOverlay, setShowLoginOverlay] = useState(false)
    const [frequencyMonthly, setfrequencyMonthly] = useState(null)

    const navigate = useNavigate();


    let loading = false;

    const submitHandler = async (e) => {
        e.preventDefault();
        if (email === emailConfirmation) {
            const data = {
                planId: planId,
                freq: freq,
                email: userInfo ? userInfo.email : email
            }

            const stripe = await stripePromise
            const res = await API.Checkout.checkoutSub(data)

            if (res && res.data && res.data.stripeSessionId) {
                await stripe.redirectToCheckout({
                    sessionId: res.data.stripeSessionId
                })
            }
        } else toast.error('Emails doesn\'t match')
    }

    const openLogin = (e) => {
        e.preventDefault();
        setShowLoginOverlay(true);
    }

    useEffect(() => {
        if (freq === "monthly") setfrequencyMonthly(true)
        else if (freq === "annually") setfrequencyMonthly(false)
        else navigate('/')
    }, [freq, setfrequencyMonthly])


    // FETCH PLAN
    useEffect(() => {

        async function fetchPlans(id) {
            try {
                let req = await API.General.getPlanById(id)
                if (req && req.data && req.data.data) {
                    setPlan({ id: req.data.data.id, ...req.data.data.attributes })
                    console.log('req.data.data', req.data.data);
                } else throw new Error('Could not fetch PLAN')
            } catch (error) {
                console.log('Err : ', error);
                navigate('/')
            }
        };

        if (planId !== undefined) fetchPlans(planId);
    }, [setPlan, planId])

    return (<>
        {(showLoginOverlay && !userInfo) && <OverlayComponent closeOverlay={() => setShowLoginOverlay(false)} fullScreen={false} />}
        <div className="mt-20 ">

            <svg
                className="absolute inset-0 pointer-events-none h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
            >
                <defs>
                    <pattern
                        id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                        width={200}
                        height={200}
                        x="50%"
                        y={-1}
                        patternUnits="userSpaceOnUse"
                    >
                        <path d="M.5 200V.5H200" fill="none" />
                    </pattern>
                </defs>
                <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                    <path
                        d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                        strokeWidth={0}
                    />
                </svg>
                <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
            </svg>
            <svg
                viewBox="0 0 1108 632"
                aria-hidden="true"
                className="absolute pointer-events-none top-52 left-[calc(50%-4rem)]  w-[69.25rem] max-w-none transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
            >
                <path
                    fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
                    fillOpacity=".2"
                    d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z"
                />
                <defs>
                    <linearGradient
                        id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
                        x1="1220.59"
                        x2="-85.053"
                        y1="432.766"
                        y2="638.714"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4F46E5" />
                        <stop offset={1} stopColor="#80CAFF" />
                    </linearGradient>
                </defs>
            </svg>

            <h1 className="text-xl md:text-4xl font-semibold text-white my-3"> Checkout</h1>
            <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 py-8 md:py-12 min-h-[80vh]">
                <h1 className="sr-only">Checkout</h1>

                <section
                    aria-labelledby="summary-heading"
                    className=" pt-6 pb-6 text-indigo-300 px-6 md:px-10 lg:col-start-2 lg:row-start-1 flex justify-center items-center lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pt-0 lg:pb-24"
                >
                    <div
                        className={classNames(
                            true ? 'bg-white/5 ring-2 ring-green-portal' : 'ring-1 ring-white/10',
                            'rounded-3xl px-12 pb-8 pt-12 xl:p-10 bg-gray-900'
                        )}
                    >
                        <div className=" w-full flex justify-between items-end">
                            {/* PLAN IMAGE */}
                            {/* {(plan && plan.image) &&
                                <img
                                    className="h-16 w-16 rounded-full bg-gray-200"
                                    src={getFullImageUrl(plan.image.data.attributes.url)}
                                    alt="plan image"
                                />
                            } */}
                        </div>
                        <div className="flex items-center justify-between gap-x-4">

                            {plan
                                ? <h2 id={plan.id} className="text-lg font-semibold leading-8 text-white">
                                    {plan.name}
                                </h2>
                                : <Skeleton baseColor='#414141' className="h-5 my-2" width="260px" />
                            }

                            <div className="flex justify-center">
                                <RadioGroup
                                    value={frequencyMonthly}

                                    className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white relative"
                                >
                                    <span className={(!frequencyMonthly ? "text-green-portal" : " text-gray-500 ") + " drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] absolute text-sm w-20 font-extrabold -top-4 left-[75%] rotate-[28deg] leading-[13px]"}>2 months FREE</span>

                                    <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                                    <RadioGroup.Option
                                        key={'monthly'}
                                        value={frequencyMonthly}
                                        onClick={() => {
                                            setSearchParams({ freq: "monthly" })
                                            setFreq("monthly")
                                        }}
                                        className={() =>
                                            classNames(frequencyMonthly ? 'bg-green-portal' : '', 'cursor-pointer rounded-full py-1 px-2.5')
                                        }
                                    >
                                        <span>Monthly</span>
                                    </RadioGroup.Option>
                                    <RadioGroup.Option
                                        key={'annually'}
                                        value={!frequencyMonthly}
                                        onClick={() => {
                                            setSearchParams({ freq: "annually" })
                                            setFreq("annually")
                                        }}
                                        className={() =>
                                            classNames(!frequencyMonthly ? 'bg-green-portal' : '', 'cursor-pointer rounded-full py-1 px-2.5')
                                        }
                                    >
                                        <span>Annually</span>
                                    </RadioGroup.Option>
                                </RadioGroup>
                            </div>

                        </div>



                        {/* PLAN PRICE */}
                        {plan ? <p className="mt-6 flex items-baseline gap-x-1">
                            <span className="text-4xl font-bold tracking-tight text-white">{(plan.price * (frequencyMonthly ? 1 : 10)).toFixed(2)}</span>
                            <span className="text-sm font-semibold leading-6 text-gray-300">$ {frequencyMonthly ? '/ month' : '/ year'}</span>
                        </p> : <Skeleton baseColor='#414141' className="h-4 my-1" width="160px" />}

                        {/* PLAN DETAILS */}
                        <Disclosure as="div" className="pt-6">
                            {({ open }) => (
                                <>
                                    <dt>
                                        <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                                            <span className="text-base font-semibold leading-7">Details</span>
                                            <span className="ml-6 flex h-7 items-center">
                                                {open ? (
                                                    <FontAwesomeIcon icon={faChevronUp} className="h-4 w-4" aria-hidden="true" />
                                                ) : (
                                                    <FontAwesomeIcon icon={faChevronDown} className="h-4 w-4" aria-hidden="true" />
                                                )}
                                            </span>
                                        </Disclosure.Button>
                                    </dt>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">

                                        {/* PLAN DESCRIPTION */}
                                        {plan
                                            ? <Markdown content={plan.description} className="mt-4 text-sm text-left leading-6 text-gray-300" />
                                            : <Skeleton baseColor='#414141' count={2} className="h-2 my-2" width="260px" />
                                        }

                                        {/* PLAN DETAILS */}
                                        <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
                                            {plan ? featuresKeys.map((feature) => {

                                                const uniqueKey = plan.id.toString() + feature.key
                                                const value = plan[feature.key] !== "-1" ? plan[feature.key] : "Ꝏ"

                                                return (
                                                    <li key={uniqueKey} className="flex gap-x-3">
                                                        <FontAwesomeIcon icon={faCheck} className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                                        <span className='  font-semibold'>{value}</span>
                                                        {feature.label}
                                                    </li>
                                                )
                                            }) : <Skeleton baseColor='#414141' className="h-3 my-1" width="260px" />}
                                        </ul>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>

                    </div>
                </section>

                <section
                    aria-labelledby="payment-heading"
                    className="py-16 px-4 flex flex-col justify-center lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pt-0 lg:pb-24"
                >
                    <form onSubmit={submitHandler} className="space-y-6" action="#" method="POST">
                        <h3 id="contact-info-heading" className="text-2xl font-semibold text-gray-100">
                            Contact information
                        </h3>

                        {userInfo && <p className="text-gray-300 w-full text-left">Connected as <span className="text-white font-semibold">@{userInfo.username}</span></p>}

                        <div>
                            <label htmlFor="email" className="sr-only">
                                Email
                            </label>
                            <input
                                disabled={userInfo}
                                value={userInfo ? userInfo.email : email}
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-portal-light focus:outline-none focus:ring-green-portal-light sm:text-sm"
                                placeholder="Email address"
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className="sr-only">
                                Confirm Email
                            </label>
                            <input
                                disabled={userInfo}
                                value={userInfo ? userInfo.email : emailConfirmation}
                                // value={emailConfirmation}
                                onChange={(e) => setEmailConfirmation(e.target.value)}
                                id="email-confirmation"
                                name="email-confirmation"
                                type="email-confirmation"
                                required
                                className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-green-portal-light focus:outline-none focus:ring-green-portal-light sm:text-sm"
                                placeholder="Confirm Email"
                            />
                        </div>

                        <button
                            onClick={(e) => {
                                return userInfo ? navigate('/authentification') : openLogin(e)
                            }}
                            className="text-gray-300 text-sm !mt-2 hover:underline">
                            {userInfo ? "Update my email" : "I already have an account"}
                        </button>

                        <div className="pt-2">
                            <button
                                type="submit"
                                className="flex mx-auto justify-center rounded-md bg-purple-700 w-fit py-2 px-6 text-base font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
                            >
                                {loading ? <Spinner /> : <span>Process Payment <FontAwesomeIcon icon={faCreditCard} className="ml-2" /></span>}
                            </button>
                        </div>
                    </form>
                </section>

            </div>
        </div >
    </>
    )
}

export default CheckOutPage;