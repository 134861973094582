import React, { memo, useCallback, useEffect, useRef } from 'react';

// THREE JS
import { useFrame, useThree } from '@react-three/fiber'
import { Effects, Stars, } from '@react-three/drei'

// ANIMATION
import gsap from 'gsap'

// MATERIAL/MODEL
import '../../../components/3DModels/Portal3DModel/PortalMaterial'
import Portal from '../../../components/3DModels/Portal3DModel/Portal3DModel';

const PortalAnimated = (props) => {

  const { delay, setPrompt } = props

  const portalRef = useRef(null)
  const starsRef = useRef(null)

  const { viewport } = useThree()

  // SOUND ADDING ATTEMPT => TO KEEP
  const playsound = useCallback((audioPath) => {

    const portalGunSound = new Audio(audioPath)

    let timer = setTimeout(() => {
      portalGunSound.volume = 0.3
      // portalGunSound.muted = true
      portalGunSound.play()

    }, ((delay * 1000) - 200));
    return () => clearTimeout(timer);

  }, [delay])

  // ANIMATE OPEN PORTAL
  const handleAnimationPortal = useCallback(() => {
    if (!!portalRef.current) {

      playsound("/Ricks Portal Gun.mp3")

      const TL = gsap.timeline({
        onComplete: () => {
          setPrompt(['Dimension ready', '💡 clic the portal or drop file to transfer'])
        }
      })

      TL.to(portalRef.current.position, { delay: delay, duration: 1.2, ease: "elastic.out(.55, 1)", z: 0 });
      TL.to(portalRef.current.scale, { duration: 1.2, ease: "elastic.out(.55, 1)", x: 1, y: 1, z: 1 }, '<');
      TL.to(portalRef.current, { duration: 1.2, ease: "none", visible: true }, '<');

    }
  }, [delay, setPrompt, playsound])


  useEffect(() => {
    handleAnimationPortal();
  }, [handleAnimationPortal])

  //==============CUSTOM COMPONENTS

  // STARS SPINNER WRAPPER
  const CustomStars = memo(() => {
    // MAKES STARS SPINNING
    useFrame((state) => { (starsRef.current.rotation.z = starsRef.current.rotation.z - 0.001) })
    return <mesh ref={starsRef} position={[0, 0, 0]}>
      <Stars count={1000} fade={true} />
    </mesh>
  })

  return (
    <mesh opacity={0} scale={0} visible={false} position={[0, 1, -200]} ref={portalRef}>
      <CustomStars />
      <mesh scale={viewport.width < 13 ? viewport.width / 15 : 1}>
        <Portal position={[0, 0, 0]} rotation={[0, 0, 0]} scale={8} />
      </mesh>
      <Effects disableGamma>
        <unrealBloomPass threshold={0.5} strength={1.5} radius={1} />
      </Effects>
    </mesh>
  )
};

PortalAnimated.propTypes = {};

PortalAnimated.defaultProps = {};

export default PortalAnimated;
