import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faBars, faTimes, faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@headlessui/react'

import { socket } from '../../context/socket';
import { usePrompt } from '../../context/PromptContext';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const menuContent = [
  {
    href: "",
    name: "Home"
  },
  {
    href: "features",
    name: "Features"
  },
  // {
  //   href: "pricing",
  //   name: "Pricing"
  // },
  {
    href: "about-us",
    name: "About"
  },
  {
    href: "contact",
    name: "Contact"
  },
]

const Header = forwardRef((props, ref) => {

  useImperativeHandle(ref, () => ({
    initDimensionTimer(timeinSec) {
      setTimer(timeinSec)
    }
  }));

  const [timer, setTimer] = useState(0)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const { setPrompt } = usePrompt()
  const { userInfo } = useAuth()

  const location = useLocation()


  return (<header className="bg-transparent w-full z-30 relative">
    <nav className="mx-auto flex max-w-7xl bg-transparent items-center justify-between p-2 lg:px-4" aria-label="Global">
      <div className="flex lg:flex-1">
        <NavLink to={'/'} className="-m-1.5 p-1.5">
          <span className="sr-only">RickTransfer</span>
          {!(location.pathname.includes('dimension') || location.pathname.includes('checkout'))
            ? <img className="h-12 w-auto" src='/img/logo192.png' alt="logo" />
            : <span className='text-gray-300 text-sm'><FontAwesomeIcon icon={faArrowLeft} /> Back </span>}
        </NavLink>
      </div>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <FontAwesomeIcon icon={faBars} className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      {!(location.pathname.includes('dimension') || location.pathname.includes('checkout')) && <div className="hidden lg:flex lg:gap-x-12">
        {menuContent.map((item) => (
          <NavLink key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-white">
            {item.name}
          </NavLink>
        ))}
      </div>}
      <div className="hidden lg:flex lg:flex-1 lg:justify-end">

        {(!userInfo && !location.pathname.includes('authentification')) && <NavLink to={'/authentification'} className="text-sm font-semibold leading-6 text-white cursor-pointer">
          Log in
          {/* <span aria-hidden="true"><FontAwesomeIcon icon={faArrowRight} /></span> */}
        </NavLink>}

        {userInfo && <NavLink to={'/authentification'} className="text-sm leading-6 text-gray-200 font-semibold cursor-pointer tracking-wider hover:text-white flex items-center justify-center"          >
          <FontAwesomeIcon icon={faUserAlt} className='text-xs mr-1' />
          <span>{userInfo.username}</span>
        </NavLink>}


      </div>
    </nav>
    <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      <div className="fixed inset-0 z-10" />
      <Dialog.Panel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
        <div className="flex items-center justify-between">
          <NavLink to={'/'} className="-m-1.5 p-1.5">
            <span className="sr-only">RickTransfer</span>
            <img className="h-10 w-auto" src='/img/logo192.png' alt="logo" />
          </NavLink>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-400"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <FontAwesomeIcon icon={faTimes} className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-6 divide-y divide-gray-500/25">
            <div className="space-y-2 py-6">
              {menuContent.map((item) => (
                <NavLink
                  onClick={() => setMobileMenuOpen(false)}
                  key={item.name}
                  to={item.href}
                  className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-900"
                >
                  {item.name}
                </NavLink>
              ))}
            </div>
            <div className="py-6">
              {(!location.pathname.includes('authentification') && userInfo) && <NavLink onClick={() => setMobileMenuOpen(false)}
                to={"/authentification"}
                className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-white hover:bg-gray-900"
              >Profile</NavLink>}

              {(!userInfo && !location.pathname.includes('authentification')) && <NavLink onClick={() => setMobileMenuOpen(false)} to={'/authentification'} className="text-sm font-semibold leading-6 text-white cursor-pointer">
                Log in <span className='ml-1' aria-hidden="true"><FontAwesomeIcon icon={faArrowRight} /></span>
              </NavLink>}
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>

    {(timer !== 0 && location.pathname.includes('dimension')) && <div className='absolute right-1/2 translate-x-1/2 bottom-0'>
      <CountDown setPrompt={setPrompt} timer={timer} setTimer={setTimer} />
    </div>}
  </header>
  )
});

const CountDown = (props) => {

  const { timer, setTimer, setPrompt } = props

  const [countDown, setCountDown] = useState(timer)

  useEffect(() => {
    if (countDown > 0) {
      let timeout = setTimeout(() => {
        if (countDown === 60) setPrompt(['Dimension will exprire in 60s'])

        if (countDown === 1) {
          // socket.emit('session_timeout', { timedOut: true }, (error) => { console.log(error) });
          setTimer(0)
          return clearTimeout(timeout)
        } else setCountDown(countDown - 1)
      }, 1000);
    } else setTimer(0)
  }, [countDown, setPrompt, setTimer]);

  const formatSeconds = (s) => {
    var date = new Date(0);
    date.setSeconds(s); // specify value for SECONDS here
    return date.toISOString().substring(11, 19).split(':').filter((el, i, arr) => !(arr.length === 3 && i === 0 && el === '00')).join(':')
  }

  return <span className={(countDown > 59 ? 'text-gray-200' : 'text-yellow-300') + ' text-xs px-2 bg-black bg-opacity-60 py-1 rounded-md'}> {formatSeconds(countDown)}</span>
}

export default Header;
