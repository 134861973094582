import { useEffect } from 'react'

import { faAdd, faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Disclosure } from '@headlessui/react'

import rick from '../../../../images/Rick-FAQ.png'
import API from '../../../../api/index'
import Markdown from '../../../../components/HTML/Markdown'
import { useStaticData } from '../../../../context/StaticDataContext'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const FAQSection = () => {

    const { faq, setFaq } = useStaticData()

    useEffect(() => {

        async function fetchFaq() {
            try {
                let req = await API.General.getFAQ()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes }
                    setFaq(obj)
                } else throw new Error('Could not fetch FAQ')
            } catch (error) {
                console.log('Err : ', error);
            }
        };

        if (!faq) fetchFaq();

    }, [faq, setFaq])


    return (
        <div className="bg-transparent relative">
            <div className="absolute inset-x-0 top-1/2  -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl">
                <svg viewBox="0 0 1313 771" aria-hidden="true" className="ml-[max(50%,38rem)] w-[82.0625rem]">
                    <path
                        id="bc169a03-3518-42d4-ab1e-d3eadac65edc"
                        fill="url(#85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3)"
                        d="M360.508 589.796 231.671 770.522 0 498.159l360.508 91.637 232.034-325.485c1.485 150.396 51.235 393.965 238.354 165.069C1064.79 143.261 1002.42-107.094 1171.72 46.97c135.44 123.252 148.51 335.641 138.11 426.428L971.677 339.803l24.062 411.461-635.231-161.468Z"
                    />
                    <defs>
                        <linearGradient
                            id="85a0eca5-25f1-4ab9-af84-4e2d8d9cdbf3"
                            x1="1313.17"
                            x2="-88.881"
                            y1=".201"
                            y2="539.417"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#4F46E5" />
                            <stop offset={1} stopColor="#80CAFF" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
                <div className="mx-auto max-w-4xl divide-y divide-white/10">
                    {faq ? <h2 className="z-10 bg-black w-fit relative rounded-md mx-auto px-2 text-xl md:text-3xl font-bold leading-10 tracking-tight text-white">{faq.title}</h2> : <Skeleton baseColor='#414141' className="h-4" width="160px" />}
                    <dl className="mt-1 md:mt-10 space-y-6 divide-y divide-white/10 relative border-none">
                        <img src={rick} alt="rick" className="absolute z-0 left-0 bottom-full h-44 w-auto border-none" />
                        {faq ? faq.qAnda.map((faq, i) => (
                            <Disclosure as="div" key={i} className="pt-6">
                                {({ open }) => (
                                    <>
                                        <dt>
                                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                                <span className="ml-6 flex h-7 items-center">
                                                    {open ? (
                                                        <FontAwesomeIcon icon={faMinus} className="h-6 w-6" aria-hidden="true" />
                                                    ) : (
                                                        <FontAwesomeIcon icon={faAdd} className="h-6 w-6" aria-hidden="true" />
                                                    )}
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                            <Markdown className="text-base leading-7 text-gray-300" content={faq.answer} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>

                        )) : <Skeleton baseColor='#414141' count={4} className="h-16" width="360px" />}
                    </dl>
                </div>
            </div>
        </div >
    )
}
export default FAQSection;