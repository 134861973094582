import React, { useEffect, useRef, useState } from 'react';

import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import createDOMPurify from "dompurify";

import api from "../../api/index";
import { useStaticData } from '../../context/StaticDataContext';

import pppl1 from '../../images/phone_ppl.png'

const DOMPurify = createDOMPurify(window);

const ContactUs = () => {

    // form data
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState("");
    // const [recaptchaRef, setRecaptchaRef] = useState("");

    // const [topics, setTopics] = useState([])
    const [baseTopic, setBaseTopic] = useState([])
    const [selectedTopics, setSelectedTopics] = useState([])

    const { topics, setTopics } = useStaticData()

    const captchaRef = useRef()

    useEffect(() => {
        async function fetchTopics() {
            try {
                let req = await api.General.getTopics()
                const data = [];
                if (req && req.data && req.data.data && Array.isArray(req.data.data)) {
                    for (let topic of req.data.data) {
                        if (topic.id && topic.attributes && topic.attributes.name) data.push({ id: topic.id, name: topic.attributes.name })
                    }
                } else throw new Error('Could not fetch SERVICES')
                if (data) setTopics(data)
            } catch (error) {
                console.log('Err : ', error);
            }
        };
        if (topics.length === 0) fetchTopics();
    }, [topics])

    useEffect(() => {
        function returnTopic() {
            let toReturn = topics
            toReturn = toReturn.sort(() => .5 - Math.random())
            setBaseTopic(toReturn)
        }
        returnTopic()
    }, [topics])

    const resetForm = () => {
        setChecked(false)
        setMessage("")
        setEmail("")
        setFullName("")
        setSelectedTopics([])
    }

    const verifyToken = async (token) => {
        try {
            let response = await api.General.verifyTokenCapchat({
                secret: process.env.REACT_APP_SECRET_KEY,
                token
            });
            return response.data;
        } catch (error) {
            console.log("error ", error);
        }
    }

    const submitContactForm = async (e) => {
        e.preventDefault();

        let token = captchaRef.current.getValue();

        if (token) {
            let valid_token = await verifyToken(token);
            if (!valid_token || !valid_token.success) return
        } else return toast.error('reCAPTCHA required.')

        if (fullName && email) {
            const attachedTopics = selectedTopics.map(t => t.id)
            let req = await api.General.sendMessage({
                data: {
                    fullName: fullName,
                    email: email,
                    message: message,
                    topics: attachedTopics
                },
            });

            if (req.status === 200) {
                // clear form
                resetForm()
                toast.success("Message sent")
            } else toast.error("Oops an error occured");

        } else {
            return toast.error("Please make sure you filled all field");
        }
    };

    const handleContactForm = (e, set) => {
        let value = e.target.value;
        set(DOMPurify.sanitize(value));
    };

    const toggleTopics = (topic) => {
        const topicIndex = selectedTopics.findIndex(t => t.id === topic.id)
        let newSelectedTopics = []
        if (topicIndex === -1) {
            newSelectedTopics = [...selectedTopics, topic]
        } else {
            const selectedTopicsCopy = [...selectedTopics]
            newSelectedTopics = selectedTopicsCopy.filter(el => el.id !== topic.id)
        }
        setSelectedTopics(newSelectedTopics)
    }

    return (
        <form onSubmit={submitContactForm} className="px-6 lg:px-8 lg:pt-14 text-left z-10 relative">
            <div className="mx-auto max-w-xl pb-24 pt-20 sm:pb-32 lg:mr-0 lg:max-w-lg lg:py-24">
                <div className="grid grid-cols-1 gap-y-6 gap-x-8 sm:grid-cols-2">
                    <div className="sm:col-span-2">
                        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-white">
                            Full name
                        </label>
                        <div className="mt-2.5">
                            <input
                                onChange={(e) => {
                                    handleContactForm(e, setFullName);
                                }}
                                value={fullName}
                                type="text"
                                maxLength="256"
                                name="fullName"
                                data-name="fullName"
                                placeholder="Rick Sanchez"
                                id="fullname"
                                required=""
                                formcontrolname="fullname"
                                className="block w-full rounded-md border-0 bg-white/5 py-2 px-3.5 text-sm leading-6 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-green-portal"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-white">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="email"
                                maxLength="256"
                                name="email"
                                data-name="email"
                                placeholder="example@gmail.com"
                                id="email"
                                required=""
                                formcontrolname="email"
                                value={email}
                                onChange={(e) => {
                                    handleContactForm(e, setEmail);
                                }}
                                className="block w-full rounded-md border-0 bg-white/5 py-2 px-3.5 text-sm leading-6 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-green-portal"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-white">
                            Message
                        </label>
                        <div className="mt-2.5">
                            <textarea
                                placeholder="Recommanded"
                                maxLength="500"
                                id="message"
                                name="message"
                                data-name="message"
                                value={message}

                                formcontrolname="message"
                                onChange={(e) => {
                                    handleContactForm(e, setMessage);
                                }}
                                className="block w-full rounded-md border-0 bg-white/5 py-2 px-3.5 text-sm leading-6 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-green-portal"
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="flex flex-col lg:flex-row w-full my-4"
                >
                    <div
                        className="block text-sm font-semibold leading-6 text-white"
                    >
                        What is it about ?
                    </div>
                    <div className='w-full flex flex-wrap justify-start space-x-2 items-start px-3 '>
                        {baseTopic.map(topic =>
                            <span
                                key={topic.id}
                                onClick={() => toggleTopics(topic)}
                                className={
                                    (selectedTopics.findIndex(el => el.id === topic.id) !== -1
                                        ? '  text-gray-100  font-bold opacity-100  space-x-1 '
                                        : '  text-gray-200  font-medium opacity-80 ')
                                    + ' text-sm cursor-pointer px-1.5 py-1 hover:scale-105'}>
                                <span className='font-extrabold text-green-portal'>#</span>
                                {topic.name}
                            </span>
                        )}
                    </div>
                </div>
                <div className='flex justify-between items-center'>
                    <img src={pppl1} alt="phone people 1" className="h-28 md:h-32 w-auto " style={{ transform: "scaleX(-1)" }} />

                    <div className='flex flex-col items-end'>
                        <ReCAPTCHA ref={captchaRef} sitekey={process.env.REACT_APP_SITE_KEY} />
                        <div className="mt-8 flex justify-end">
                            <button
                                type="submit"
                                className="rounded-md bg-green-portal px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-portal-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
                            >
                                Send message
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    )
};

export default ContactUs;