import React, { useEffect } from "react";

import API from '../../api/index'
import Markdown from "../../components/HTML/Markdown";
import { useStaticData } from "../../context/StaticDataContext";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const Stats = () => {

    const { stats, setStats } = useStaticData()

    useEffect(() => {
        async function fetchStats() {
            try {
                let req = await API.General.getStats()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes }
                    setStats(obj)
                } else throw new Error('Could not fetch STATS')
            } catch (error) {
                console.log('Err : ', error);
            }
        };
        if (!stats) fetchStats();
    }, [stats, setStats])

    return (
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8 text-left relative mb-24">

            <div className="absolute inset-x-0 bottom-10  flex transform-gpu justify-center overflow-hidden blur-3xl">
                <svg viewBox="0 0 1108 632" aria-hidden="true" className="w-[69.25rem] flex-none">
                    <path
                        fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
                        fillOpacity=".2"
                        d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z"
                    />
                    <defs>
                        <linearGradient
                            id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
                            x1="1220.59"
                            x2="-85.053"
                            y1="432.766"
                            y2="638.714"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#4F46E5" />
                            <stop offset={1} stopColor="#80CAFF" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>

            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                {stats ? <h2 className="text-base font-semibold leading-8 text-green-portal">{stats.subTitle}</h2> : <Skeleton baseColor='#414141' className="h-3" width="160px" />}
                {stats ? <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
                    {stats.title}
                </p> : <Skeleton baseColor='#414141' className="h-6 my-2" width="300px" />}
                {stats ? <Markdown content={stats.description} className="mt-6 text-lg leading-8 text-gray-300" /> : <Skeleton baseColor='#414141' count={5} className="h-3" width="260px" />}
            </div>



            <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-10 text-white sm:mt-20 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">

                {stats ? stats.stats.map((stat) => (
                    <div key={stat.id} className="flex flex-col gap-y-3 border-l border-white/10 pl-6">
                        <dt className="text-sm leading-6">{stat.label}</dt>
                        <dd className="order-first text-3xl font-semibold tracking-tight">{stat.value}</dd>
                    </div>
                )) : [1, 2, 3, 4].map(nb => <div key={nb}>
                    <Skeleton baseColor='#414141' className="h-4 my-2" width="200px" />
                    <Skeleton baseColor='#414141' className="h-3 " width="300px" />
                </div>)
                }

            </dl>

        </div>
    )
}

export default Stats