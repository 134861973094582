import React, { useEffect, useRef } from "react";

import gsap from "gsap";
import { useFrame, useThree } from '@react-three/fiber'
import { Float } from "@react-three/drei";

import TextCustom from "../../../../../../components/THREEJS/TextCustom";

import { degToRad } from "three/src/math/MathUtils";
import PortalGunAnimated from "../../../../../Dimension/threejs/PortalGunAnimated";
import font from '../../../../../../fonts/Get Schwifty_Regular.json'

const StartCTA = () => {

    const { viewport } = useThree()

    const btnRef = useRef()
    const portalRef = useRef()

    useEffect(() => {
        if (btnRef && btnRef.current) {
            const TL = gsap.timeline()
            TL.to(btnRef.current.position, { delay: 1.5, duration: .5, ease: 'power2.out', x: 0 })
        }
        if (portalRef && portalRef.current) {
            const TL = gsap.timeline()
            TL.to(portalRef.current.position, { duration: .5, ease: 'power2.out', z: -4 })
            TL.to(portalRef.current.scale, { duration: .5, ease: 'power2.out', x: 2, y: 2, z: 2 })
        }
    }, [])

    const PortalGunRotate = () => {

        const gunRef = useRef()

        useFrame(() => {
            gunRef.current.rotation.y += 0.01;
        });

        return <Float floatIntensity={6} rotationIntensity={0} speed={2} >
            <mesh ref={gunRef} position={[0, 0, 0]} scale={4} >
                <PortalGunAnimated animateOn={false} />
            </mesh>
        </Float>
    }

    return (
        <group
            onPointerOut={() => document.body.style.cursor = 'auto'}
            onPointerOver={() => document.body.style.cursor = 'pointer'}
            scale={viewport.width < 13 ? (viewport.width / 15) : 1.3}
        >
            <group scale={.9} position={[0, -2, -5]} >
                <mesh ref={btnRef} position={[50, -3, 2]} rotation={[degToRad(-25), 0, 0]}>
                    <Float floatIntensity={3} rotationIntensity={0} speed={2}>
                        <TextCustom rotation={[-degToRad(10), 0, 0]} position={[0, 0, 1]} text={'START'} params={{
                            size: 1.4, height: .4, curveSegments: 64,
                        }} />
                    </Float>
                </mesh>
                <mesh ref={portalRef} position={[0, 3, -100]} scale={0}>
                    <PortalGunRotate />
                </mesh>
            </group>

        </group>)


}

export default StartCTA