import React from "react";
import { useAuth } from "../../context/AuthContext";
import LogIn from "./components/LogIn";
import Profile from "./components/Profile";
import SignUp from "./components/SignUp";
import { useParams, useNavigate } from 'react-router-dom';
import rmWallapaper from '../../images/rm-wallpaper.png'

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

const AuthentificationPage = () => {

    const { userInfo } = useAuth()

    const { '*': mode } = useParams()

    const navigate = useNavigate()

    return <div className='py-2 md:py-6 bg-opacity-5 min-w-fit w-full mx-auto rounded-xl mt-4 relative'>
        <div className="absolute flex-1 bottom-0 pointer-events-none">

            <div className="relative z-10" aria-hidden="true">
                <div className="absolute -inset-x-20 top-0 bg-gradient-to-b from-black pb-[15%]" />
            </div>

            <img alt='R&M Wallpaper' src={rmWallapaper} className="flex-1 opacity-40" />

            <div className="relative z-10" aria-hidden="true">
                <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-black pt-[15%]" />
            </div>

        </div>

        {userInfo
            ? <Profile />
            : <div className='flex flex-col justify-start w-full'>
                {mode === 'register' ? <SignUp showLogin /> : <LogIn loginCallBack={() => navigate('/authentification')} />}
            </div>
        }
    </div>

}

export default AuthentificationPage;