import toast from "react-hot-toast";
import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL

const ax = axios.create();

//===============================================================================================================
// config header ================================================================================================
ax.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("tkn");
        const language = window.localStorage.getItem("i18n") || "en";

        config.headers["Access-Control-Allow-Origin"] = '*';

        if (token && !config.url.endsWith("refresh")) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        if (language) config.headers["Accept-Language"] = `${language}`;

        return config;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    }
);

//=====================================================================================================================
// config interceptors ================================================================================================
ax.interceptors.response.use(null, (err) => {
    if (err.message === "Network Error" && !err.response) {
        toast.error(`Network Error`);
    }

    const { status, data } = err.response || {};

    if (status === 400) {
        const msg = err.response.data.error.message ? err.response.data.error.message : err.message
        if (msg !== undefined) toast.error(status + ' ' + msg)
        else toast.error(status)
    }

    if (status === 404) toast.error("404 Unknow endpoint..");

    if (status === 401 || status === 403) {
        window.localStorage.removeItem("tkn");
        // window.location.href = "/";
        const msg = err.response.data.error.message ? err.response.data.error.message : err.message
        if (msg !== undefined) toast.error(status + ' ' + msg)
        else toast.error(status)
        return Promise.reject(err);
    }

    if (status === 500) {
        const msg = err.response.data.error.message ? err.response.data.error.message : err.message
        if (msg !== undefined && msg !== "Internal Server Error") toast.error(status + ' ' + msg)
        // else toast.error(status)
    }

    throw err.response;
});




//=====================================================================================================================
// Build API ================================================================================================

const General = {
    getTopics: () => ax.get("/api/topics"),
    getPlans: () => ax.get("/api/plans"),
    getPlanById: (id) => ax.get(`/api/plans/${id}?populate=%2A`),
    getPricing: () => ax.get("/api/pricing"),
    getFAQ: () => ax.get("/api/faq?populate=%2A"),
    getStats: () => ax.get("/api/statistic?populate=%2A"),
    getLogoCloud: () => ax.get("/api/getLogoCloud"),
    getTestimonial: () => ax.get("/api/testimonialSectionData"),
    getAbout: () => ax.get("/api/about-me?populate=%2A"),
    getService: () => ax.get("/api/service?populate=%2A"),
    getContactUs: () => ax.get("/api/contact-us"),
    getPrivacyPolicy: () => ax.get("/api/privacy-policy"),
    getTermsOfUses: () => ax.get("/api/terms-of-use"),
    verifyTokenCapchat: (data) => ax.post("/api/verifiy-token-captcha", data),
    sendMessage: (data) => ax.post("/api/messages", data),
    log: (data) => ax.post("/api/logs", data)
};

const Checkout = {
    checkoutSub: (data) => ax.post("/api/checkoutSub", data),
    confirmPayment: (data) => ax.post("/api/confirmPayment", data),
}



// const Authorization = {

// };

const Session = {
    init: (data) => ax.post("/api/initSession", data),
    requestActivation: (data) => ax.post("/api/requestActivationSession", data),
    requestJoin: (data) => ax.post("/api/requestJoinSession", data),
    // isSessionLinked: (data) => ax.post("/api/isSessionLinked", data),
    checkTokenInfo: (data) => ax.post("/api/checkTokenInfo", data),
    inviteByEmail: (data) => ax.post("/api/inviteByEmail", data),
};

const SessionItems = {

    uploadSessionItemWithFiles: (data) => ax.post("/api/uploadItem", data),
    deleteSessionItemWithFiles: (itemId, fileId) => ax.post("/api/deleteItem", { itemId, fileId })


};

const Media = {
    getFile: (url) => ax.get(url, {
        responseType: 'blob',
    }),

};

const Account = {
    register: (data) => ax.post("/api/auth/local/register", data),
    login: (data) => ax.post("/api/auth/local", data),
    updatePassword: (data) => ax.post("/api/auth/change-password", data),
    refreshToken: (data) => ax.post("/api/auth/refreshToken", data),
    getTknInfo: (tkn) => ax.post("/api/auth/getTknInfo", tkn),
    checkUserNameAvailability: (data) => ax.post("/api/auth/checkUserNameAvailability", data),
    getProfileInfo: () => ax.post("/api/auth/getProfileInfo")

};


//Build export var
const routes = {
    Account,
    // Authorization,
    Session,
    SessionItems,
    Media,
    General,
    Checkout
};

export default routes;





