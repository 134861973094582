import React, { useCallback } from 'react'

// THREE JS
import * as THREE from 'three'
import { useLoader } from '@react-three/fiber'

import TextCustom from './THREEJS/TextCustom'

import icons from './FileItem/FileToIcon/jsons/icons.json'
import { Float, RoundedBox } from '@react-three/drei'

import { getFullImageUrl } from '../helpers/functions'

const imageExtensions = [
    "bmp",
    "emf",
    "gif",
    "jp2",
    "jpeg",
    "jpg",
    "png",
    "pnm",
    "ppm",
    "tga",
    "tif",
    "tiff",
    "webp",
    "xbm",
    "xpm"
]

const FileItem = (props) => {

    const { name, ext, url, b64 } = props

    const isImage = imageExtensions.includes(ext.replace('.', ''))

    const extensionToLogo = useCallback(() => {

        const extension = ext.replace('.', '').toString()

        if (icons[extension] !== undefined) {
            return require(`./FileItem/FileToIcon/icons/file_type_${extension}@3x.png`)
        } else {
            const keysArr = Object.keys(icons)
            const key = keysArr.find(k => {
                return icons[k] &&
                    icons[k].syntaxes &&
                    icons[k].syntaxes[0] &&
                    icons[k].syntaxes[0].extensions &&
                    (icons[k].syntaxes[0].extensions.indexOf(`.${extension}`) !== -1 || icons[k].syntaxes[0].extensions.indexOf(extension) !== -1)
            })
            return key ? require(`./FileItem/FileToIcon/icons/file_type_${key}@3x.png`) : require('./FileItem/FileToIcon/icons/file_type_default@3x.png')
        }
    }, [ext])

    const getFilePreview = useCallback(() => {
        // check if extension correspond to image & check for url
        if (isImage && url) {
            return b64 ? url : getFullImageUrl(url)
        } else return extensionToLogo()
    }, [ext])

    const icon = getFilePreview()
    const texture = useLoader(THREE.TextureLoader, icon)

    const shortName = (name) => {
        if (name) {
            let newName = []
            const nameArr = name.split('')
            nameArr.forEach((ch, i) => {
                if (i >= 14) return 0
                else newName.push(ch)
            })
            if (nameArr.length > 15) newName.push('..')
            return newName.join('')
        } else return 'N/a'
    }

    return <Float floatIntensity={1} rotationIntensity={0} speed={2}>
        <group onPointerOver={() => document.body.style.cursor = 'pointer'} onPointerOut={() => document.body.style.cursor = 'auto'} {...props}>

            {/* Transparent black support  */}
            <mesh position={[-.02, 1.4, -1]} >
                <RoundedBox args={[4.5, 4, 0.25]} radius={0.3} smoothness={12} >
                    <meshLambertMaterial attach="material" color={"black"} transparent={true} opacity={.8} />
                </RoundedBox>
            </mesh>

            {/* ICON LOGO FILE OR PREVIEW*/}
            <mesh position={[0, 1.8, 0]} scale={isImage ? 1 : .8}>
                <planeBufferGeometry attach={"geometry"} args={[1.8, 1.8, 1.8]} />
                <meshPhongMaterial attach={"material"} color={"white"} map={texture} transparent={true} emissive={"white"} opacity={isImage ? 1 : .8} emissiveIntensity={isImage ? .01 : 1} />
            </mesh>

            {/* FILE NAME */}
            <TextCustom color={"#ffffff"} position={[0, .5, 0]} rotation={[0, 0, 0]} text={shortName(name.replace(ext, ''))} params={{
                size: .30, height: .01, curveSegments: 64,
            }} />
            <TextCustom color={"#ffffff"} position={[0, 0, 0]} rotation={[0, 0, 0]} text={ext} params={{
                size: .25, height: .01, curveSegments: 64,
            }} />

        </group>
    </Float>


}

export default FileItem;