import React, { useEffect } from "react";

import toast from "react-hot-toast";

import ContactUs from "./ContactUs";

import API from '../../api/index'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import pppl2 from '../../images/phone_ppl2.png'
import { useStaticData } from "../../context/StaticDataContext";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ContactPage = () => {
    const { contactus, setContactUs } = useStaticData()

    useEffect(() => {
        async function fetchContactUs() {
            try {
                let req = await API.General.getContactUs()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes }
                    setContactUs(obj)
                } else throw new Error('Could not fetch SERVICES')
            } catch (error) {
                console.log('Err : ', error);
            }
        };
        if (!contactus) fetchContactUs();
    }, [contactus, setContactUs])



    const subNewsLetterHandler = async (e) => {
        e.preventDefault();

        const email = e.target && e.target[0] && e.target[0].value ? e.target[0].value : null

        if (email) {

            let req = await API.General.sendMessage({
                data: {
                    fullName: 'NEWSLETTER SUB',
                    email: email,
                },
            });

            if (req.status === 200) {
                e.target[0].value = ""
                toast.success("Thanks for subscribing ❤️")
            } else toast.error("Oops an error occured");


        }

    };

    return (
        <>
            <div className="relative isolate text-left pt-6">
                <div className="flex w-full px-4 py-6 justify-around items-center  mt-16 md:mt-32">
                    <h1 className="text-5xl md:text-7xl text-[#01B5CC] font-bold font-get_schwifty tracking-widest" style={{ textShadow: "rgb(178, 224, 40) -1px -1px 0px, rgb(178, 224, 40) 1px -1px 0px, rgb(178, 224, 40) -1px 1px 0px, rgb(178, 224, 40) 1px 1px 0px" }}>Contact</h1>
                </div>
                <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
                    <div className="relative px-6 pt-14 lg:static lg:px-8">
                        <div className="mx-auto max-w-xl pt-24 pb-20 sm:pt-32 lg:mx-0 lg:max-w-lg lg:py-24">
                            {/* DECORATION  */}
                            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
                                <svg
                                    className="absolute inset-0 h-full w-full stroke-gray-700 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                                    aria-hidden="true"
                                >
                                    <defs>
                                        <pattern
                                            id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                                            width={200}
                                            height={200}
                                            x="100%"
                                            y={-1}
                                            patternUnits="userSpaceOnUse"
                                        >
                                            <path d="M130 200V.5M.5 .5H200" fill="none" />
                                        </pattern>
                                    </defs>
                                    <svg x="100%" y={-1} className="overflow-visible fill-gray-800/20">
                                        <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                                    </svg>
                                    <rect
                                        width="100%"
                                        height="100%"
                                        strokeWidth={0}
                                        fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)"
                                    />
                                </svg>
                                <svg
                                    className="absolute top-[calc(100%-13rem)] -left-56 w-[72.1875rem] transform-gpu blur-3xl lg:top-[calc(50%-7rem)] lg:left-[max(-14rem,calc(100%-59rem))]"
                                    viewBox="0 0 1155 678"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill="url(#0a9a5302-e517-46c6-85f0-d826aa6a313e)"
                                        fillOpacity=".2"
                                        d="M317.219 159.025 203.852 0 0 239.659l317.219-80.634 204.172 286.402c1.307-132.337 45.083-346.658 209.733-145.248C936.936 551.942 882.053 772.234 1031.02 636.67c119.18-108.452 130.68-295.338 121.53-375.224L855 379l21.173-362.054-558.954 142.079Z"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="0a9a5302-e517-46c6-85f0-d826aa6a313e"
                                            x1="1155.49"
                                            x2="-78.208"
                                            y1="677.823"
                                            y2="203.355"
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop stopColor="#4F46E5" />
                                            <stop offset={1} stopColor="#80CAFF" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>

                            {contactus
                                ? <h1 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">{contactus.title}</h1>
                                : <Skeleton baseColor='#414141' className="h-5 my-3" width="260px" />}

                            {contactus
                                ? <p className="mt-6 text-lg leading-8 text-gray-300">{contactus.description}</p>
                                : <Skeleton baseColor='#414141' count={2} className="h-4 my-1" width="300px" />}

                            <div className="flex justify-between w-full items-center">
                                {contactus ? <dl className="mt-10 space-y-4 text-base leading-7 text-gray-300">

                                    <div className="flex gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">Address</span>
                                            <FontAwesomeIcon icon={faBuilding} className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd>
                                            {contactus.city}
                                        </dd>
                                    </div>
                                    <div className="flex gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">Telephone</span>
                                            <FontAwesomeIcon icon={faPhone} className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd>
                                            <a className="hover:text-white" href="tel:+1 (555) 234-5678">
                                                {contactus.phone}
                                            </a>
                                        </dd>
                                    </div>
                                    <div className="flex gap-x-4">
                                        <dt className="flex-none">
                                            <span className="sr-only">Email</span>
                                            <FontAwesomeIcon icon={faEnvelope} className="h-7 w-6 text-gray-400" aria-hidden="true" />
                                        </dt>
                                        <dd>
                                            <a className="hover:text-white" href="mailto:hello@example.com">
                                                {contactus.email}
                                            </a>
                                        </dd>
                                    </div>
                                </dl> : <Skeleton baseColor='#414141' count={3} className="h-6 my-5" width="200px" />}
                                <img src={pppl2} alt="phone people 2" className="h-28 md:h-32 w-auto" />
                            </div>

                        </div>
                    </div>
                    <ContactUs />
                </div>
                <div className="relative" aria-hidden="true">
                    <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-black pt-[10%]" />
                </div>
            </div>

            {/* Newsletter section */}
            <div className="mx-auto  max-w-7xl  sm:px-6 lg:px-8">
                <div className="relative isolate overflow-hidden px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
                    {contactus ? <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        {contactus.newsLetterTitle}
                    </h2> : <Skeleton baseColor='#414141' className="h-6 my-3" width="260px" />}
                    {contactus ? <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
                        {contactus.newsLetterDescription}
                    </p> : <Skeleton baseColor='#414141' count={3} className="h-3 my-1" width="300px" />}
                    <form onSubmit={subNewsLetterHandler} className="mx-auto mt-10 flex max-w-md gap-x-4">
                        <label htmlFor="email-address" className="sr-only">
                            Email address
                        </label>
                        <input
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
                            placeholder="Enter your email"
                        />
                        <button
                            type="submit"
                            className="flex-none rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            Notify me
                        </button>
                    </form>
                </div>
            </div>
        </>
    )

}

export default ContactPage