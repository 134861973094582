import React, { forwardRef, useImperativeHandle, useState } from 'react'

import TypingText from './TypingText'

const Terminal = forwardRef((props, ref) => {

    const [lines, setLines] = useState([]);
    const [linesAnimatedOut, setLinesAnimatedOut] = useState([]);
    const [timeouts, setTimeouts] = useState([]);

    useImperativeHandle(ref, () => ({
        clearTerminal() {
            setLines([])
        },
        setPrompt(lines) {
            newLinesHandler(lines)
        }
    }));

    const newLinesHandler = (newlines) => {
        const antiSpamLines = newlines.filter(l => lines.indexOf(l) === -1)
        antiSpamLines.forEach((txt, i) => {
            let timer = setTimeout(() => {
                addLine(txt, i)
            }, i * 1500);
            return () => clearTimeout(timer);
        });
    }

    const addLine = (line, indx) => {
        const timeoutId = setTimeout(() => {
            setTimeouts((prevTimeouts) => prevTimeouts.filter((id) => id !== timeoutId));
            setLinesAnimatedOut((prevLines) => [...prevLines, line]);
            let timer = setTimeout(() => {
                setLines((prevLines) => prevLines.filter((l) => l !== line));
                setLinesAnimatedOut((prevLines) => prevLines.filter((l) => l !== line));
            }, 1000);
            return () => clearTimeout(timer);
        }, 8000 + indx * 800);
        setLines((prevLines) => [...prevLines, line]);
        setTimeouts((prevTimeouts) => [...prevTimeouts, timeoutId]);
    };

    return <div className="wrapper overflow-hidden flex flex-col items-start px-1 py-2 bg-black bg-opacity-80">
        {lines.map((line, index) =>
            <div key={index} className={linesAnimatedOut.includes(line) ? "line-fade-out " : ""}>
                <TypingText txt={'> ' + line + ' '} isLast={index === lines.length - 1} />
            </div>
        )}
    </div >
})



export default Terminal