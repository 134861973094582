import React, { useRef } from 'react';

// THREE JS
import { useFrame, useThree } from '@react-three/fiber'



const Portal3DModel = (props) => {
    const { size } = useThree()
    const ref = useRef()
    useFrame((state) => (ref.current.material.time = state.clock.getElapsedTime() / 15))

    return (
        <mesh {...props} ref={ref} >
            <circleGeometry args={[1, 36]} />
            <portalMaterial
                toneMapped={false}
                resolution={[size.width, size.height]}
                color0={[1, 5, 1]}
                color1={[25, 200, 25]}
                color2={[44, 97, 15]}
                color3={[14, 28, 5]}
                color4={[255, 255, 255]}
                color5={[74, 150, 0]}

            />
        </mesh>
    )
}

export default Portal3DModel;