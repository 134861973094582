import React, { useEffect } from "react";
import Markdown from '../components/HTML/Markdown'
import { useStaticData } from "../context/StaticDataContext";

import API from '../api/index'

const TermsOfUses = () => {

    const { termsOfuses, setTermsOfuses } = useStaticData()

    // FETCH TermsOfUses
    useEffect(() => {

        async function fetchTermsOfUses() {
            try {
                let req = await API.General.getTermsOfUses()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes }
                    setTermsOfuses(obj)
                } else throw new Error('Could not fetch TermsOfUses')
            } catch (error) {
                console.log('Err : ', error);
            }
        };

        if (!termsOfuses) fetchTermsOfUses();

    }, [termsOfuses, setTermsOfuses])

    return <div className="h-full w-full mt-12 px-6 sm:px-12 md:px-24 lg:px-40 py-12 text-white text-left markdown">

        {termsOfuses && <Markdown content={termsOfuses.content} />}

    </div>
}

export default TermsOfUses