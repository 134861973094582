import React, { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'

import * as THREE from 'three'
import { useLoader } from '@react-three/fiber'

import gsap from 'gsap'

import { degToRad } from 'three/src/math/MathUtils'

import TextCustom from "../../../../../../components/THREEJS/TextCustom";
import { Float } from '@react-three/drei'

const QRBOx = (props) => {

    const { qrcode, scale, url } = props

    const cubeRef = useRef()
    const textRef = useRef()

    useEffect(() => {
        if (cubeRef.current) {
            const introTl = gsap.timeline();

            introTl.to(textRef.current.position, { duration: .8, ease: "power2.out", x: 0 })
            introTl.to(cubeRef.current.position, { duration: 1.5, ease: "power2.out", y: 0 }, '<')

        }
    }, [])

    const animateClick = () => {
        if (cubeRef.current) {
            navigator.clipboard.writeText(url)
            toast.success(`URL copied to clipdoard`)

            const boxTl = gsap.timeline();

            const coord = { x: degToRad(Math.random() * 360), y: degToRad(Math.random() * 360), z: degToRad(Math.random() * 360) }
            const base = { x: degToRad(0), y: degToRad(0), z: degToRad(0) }

            boxTl.to(cubeRef.current.rotation, { duration: .6, ease: "power4.out", ...coord })
            boxTl.to(cubeRef.current.rotation, { duration: .4, ease: "power4.in", ...base }, '>')
        }
    }

    const texture = useLoader(THREE.TextureLoader, qrcode)

    return <group scale={scale}>
        <Float floatIntensity={.5} rotationIntensity={0} speed={2}>
            <mesh position={[0, -8, 0]} onClick={animateClick} ref={cubeRef} >
                <boxBufferGeometry attach={"geometry"} />
                <meshLambertMaterial attach={"material"} color="white" map={texture} />
            </mesh>
        </Float>
        <mesh position={[-8, 0, .1]} ref={textRef}>
            <TextCustom position={[0, -0.7, .5]} rotation={[-.3, 0, 0]} text={'SCAN'} params={{
                size: .12, height: .01, curveSegments: 64,

            }} />
        </mesh>
    </group>

}

export default QRBOx;