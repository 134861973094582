import React, { useEffect } from "react";

import AboutMe from "./AboutMe";

import Markdown from "../../components/HTML/Markdown";

import wpp from '../../images/wallpaper.png'
import api from '../../api/index'
import { NavLink } from "react-router-dom";
import { useStaticData } from "../../context/StaticDataContext";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const AboutUsPage = () => {

    const { about, setAbout } = useStaticData()

    useEffect(() => {

        async function fetchAbout() {
            try {
                let req = await api.General.getAbout()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes, img: req.data.data.attributes.profilePic.data ? req.data.data.attributes.profilePic.data.attributes.url : null }
                    setAbout(obj)
                } else throw new Error('Could not fetch ABOUT')
            } catch (error) {
                console.log('Err', error);
            }
        };
        if (!about) fetchAbout();
    }, [about, setAbout])

    return (
        <div className="flex-1 h-full">
            <div className=" py-16 sm:py-28 text-left ">
                <div className="flex w-full px-4 py-4 justify-around items-center relative  z-10">
                    <h1
                        className="text-5xl md:text-7xl text-[#01B5CC] font-bold font-get_schwifty tracking-widest text-center"
                        style={{ textShadow: "rgb(178, 224, 40) -1px -1px 0px, rgb(178, 224, 40) 1px -1px 0px, rgb(178, 224, 40) -1px 1px 0px, rgb(178, 224, 40) 1px 1px 0px" }}
                    >About</h1>
                </div>

                <div className="mx-auto relative pb-20">
                    <div className="absolute bottom-[50px]">
                        <div className="relative  -mt-24 md:-mt-36 lg:-mt-48 z-0  pointer-events-none">
                            <div className="mx-auto">
                                <div className="opacity-30">
                                    <div className="relative" aria-hidden="true">
                                        <div className="absolute -inset-x-20 top-0 bg-gradient-to-b from-black pb-[15%]" />
                                    </div>
                                    <img
                                        className="mb-[-15%] rounded-xl shadow-2xl ring-1 ring-gray-900/10 "
                                        src={wpp}
                                        alt="rick and morty wallpaper"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mx-auto max-w-2xl lg:max-w-7xl px-6 lg:px-8 relative  z-10">
                        {about ? <p className="text-base font-semibold leading-7 text-green-portal">{about.storySubTitle}</p> : <Skeleton baseColor='#414141' className="h-3 my-1" width="160px" />}
                        {about ? <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">{about.storyTitle}</h1> : <Skeleton baseColor='#414141' className="h-5 my-3" width="260px" />}
                        <div className="mt-10 grid max-w-xl grid-cols-1 gap-8 text-base leading-7 text-gray-200 lg:max-w-none lg:grid-cols-2">
                            <>
                                {about ? <div>
                                    <Markdown content={about.storyBodyCol1} className="mt-6 max-w-2xl text-base leading-7 text-gray-300" />
                                </div> : <Skeleton baseColor='#414141' count={6} className="h-5 my-3" width="300px" />}
                                {about ? <div>
                                    <Markdown content={about.storyBodyCol2} className="mt-6 max-w-2xl text-base leading-7 text-gray-300" />
                                </div> : <Skeleton baseColor='#414141' count={6} className="h-5 my-3" width="300px" />}
                            </>

                        </div>
                        <div className="mt-10 flex">
                            <NavLink
                                to={"/#^top"}
                                className=" rounded-md bg-green-portal px-3.5 py-2.5  font-semibold text-white shadow-sm hover:bg-green-portal-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal-light mx-auto text-xl cursor-pointer"
                            >
                                Start NOW
                            </NavLink>
                        </div>
                    </div>
                </div>

            </div>
            <AboutMe about={about} />
        </div>
    )

}

export default AboutUsPage