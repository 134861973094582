import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";

const EmailConfirmedPage = () => {

    return (
        <main className="grid min-h-[73vh] h-full place-items-center  py-24 px-6 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-green-portal">Validated</p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-50 sm:text-5xl">Account activated !</h1>
                <p className="mt-6 text-base leading-7 text-gray-200">Thanks for verifiing you email, you can now enjoy exclusive services</p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                    <NavLink
                        to={"/authentification"}
                        className="rounded-md bg-green-portal px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-portal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
                    >
                        Log In
                    </NavLink>

                </div>
            </div>
        </main>
    )
}

export default EmailConfirmedPage;