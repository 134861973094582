/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Bob.Ho (https://sketchfab.com/Bob.Ho)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/rick-and-morty-portal-gun-c5f7b4a950de4002b448ac65c6b0207b
title: Rick and Morty - Portal Gun
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useEffect } from 'react'
import { Color } from 'three'

export function PortalGun3DModel(props) {
  const { nodes, materials } = useGLTF('/model/portalGunModel.gltf')


  // DEFINE REFS TO RECONFIG MODEL
  const bodyRef = useRef()
  const txtRef = useRef()
  const bocalRef = useRef()


  useEffect(() => {

    // FLUID RESERVE ON TOP OF GUN
    if (bocalRef.current) {
      // bocalRef.current.material.transparent = true
      // bocalRef.current.material.emissive = new Color("#26ff00")
      // bocalRef.current.material.emissiveIntensity = 1
      // bocalRef.current.material.metalness = 1
      // bocalRef.current.material.opacity = .1
      // bocalRef.current.material.roughness = 0.01
      // bocalRef.current.material.metalness = 0.9
      // bocalRef.current.material.roughness = 0.01
    }

    // RED TEXT ON GUN
    if (txtRef.current) {
      txtRef.current.material.emissiveIntensity = .12
    }

    // GUN BODY
    if (bodyRef.current) {
      bodyRef.current.material.roughness = 0.01
      bodyRef.current.material.metalness = 0.9
    }

  }, [])



  return (
    <group  {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>

          {/* OLD FLUID MODEL  */}
          {/* 
          <group position={[-47.32, 43.78, 0]} rotation={[0.91, 0.22, -1.14]}>
            <mesh ref={energyRef} geometry={nodes.Subdivision_Surface_2_Material011_0.geometry} material={materials['Material.011']} />
            <Sphere scale={.09} visible args={[1, 100, 200]} >
              <MeshDistortMaterial emissive={"#26ff00"} emissiveIntensity={1} color="#55ff00" attach="material" distort={.8} speed={10} />
            </Sphere>
          </group> 
          */}


          {/* RED TEXT ON GUN */}
          <group position={[35.7, 9.23, 0]} rotation={[-Math.PI / 2, 0.4, Math.PI / 2]}>
            <mesh ref={txtRef} geometry={nodes.Extrude_Material007_0.geometry} material={materials['Material.007']} />
          </group>


          {/* FLUID RESERVE ON TOP OF GUN */}
          <group position={[-47.29, -21.56, 0]}>
            <mesh ref={bocalRef} geometry={nodes.Capsule_Material002_0.geometry} material={materials['Material.002']} />
          </group>

          {/* ??? */}
          <group position={[-47.29, 0.7, 0]}>
            <mesh geometry={nodes.Tube_Material003_0.geometry} material={materials['Material.003']} />
          </group>
          <group position={[27.46, 2.78, 0]}>
            <mesh geometry={nodes.Cube_1_Material004_0.geometry} material={materials['Material.004']} />
            <mesh geometry={nodes.Cube_1_Material006_0.geometry} material={materials['Material.006']} />
          </group>
          <group position={[27.46, 1.39, 0]}>
            <mesh geometry={nodes.Cube_Material004_0.geometry} material={materials['Material.004']} />
          </group>
          <group position={[62.21, 7.1, 0]} rotation={[0, 0, -0.61]}>
            <mesh geometry={nodes.Cube_2_Material005_0.geometry} material={materials['Material.005']} />
          </group>
          <group position={[69.09, -2.16, 0]} rotation={[0, 0, -0.44]}>
            <mesh geometry={nodes.Cylinder_2_Material004_0.geometry} material={materials['Material.004']} />
          </group>
          <group position={[70.51, 0.9, 0]} rotation={[0, 0, -0.44]}>
            <mesh geometry={nodes.Cylinder_1_Material004_0.geometry} material={materials['Material.004']} />
          </group>
          <group position={[-16.73, -14.05, 0]}>
            <group position={[-94.77, 0, 30.76]} rotation={[0, 0, Math.PI / 2]}>
              <mesh geometry={nodes.Cylinder_1_2_Material008_0.geometry} material={materials['Material.008']} />
            </group>
          </group>
          <group position={[-111.51, -14.05, 0]} rotation={[0, 0, Math.PI / 2]}>
            <mesh geometry={nodes.Cylinder_Material008_0.geometry} material={materials['Material.008']} />
          </group>

          {/* GUN BODY */}
          <group position={[-22.29, -14.05, 0]}>
            <mesh ref={bodyRef} geometry={nodes.Subdivision_Surface_Material013_0.geometry} material={materials['Material.013']} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/model/portalGunModel.gltf')
