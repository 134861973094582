import React, { useEffect, useState } from "react";

import API from '../api/index'

import Markdown from "../components/HTML/Markdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import rick from '../images/Rick-FAQ.png'
import { RadioGroup } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { useStaticData } from "../context/StaticDataContext";
import { useAuth } from "../context/AuthContext";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const featuresKeys = [
    { key: 'sessionPerDay', label: ' linkup / day' },
    { key: 'sessionPerMonth', label: ' linkup / month' },
    { key: 'maxMOPerSession', label: ' MO / linkup' },
    { key: 'sessionInitTokenExpireIn', label: ' min / linkup' }
]

const PricingPage = () => {

    const [frequencyMonthly, setFrequencyMonthly] = useState(true)

    const { plans, setPlans, pricing, setPricing, faq, setFaq } = useStaticData()

    const { userInfo } = useAuth()

    useEffect(() => {
        console.log('userInfo', userInfo);
    }, [userInfo])

    // FETCH FAQ
    useEffect(() => {

        async function fetchFaq() {
            try {
                let req = await API.General.getFAQ()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes }
                    setFaq(obj)
                } else throw new Error('Could not fetch FAQ')
            } catch (error) {
                console.log('Err : ', error);
            }
        };

        if (!faq) fetchFaq();

    }, [faq, setFaq])


    // FETCH PRICING
    useEffect(() => {
        async function fetchPricing() {
            try {
                let req = await API.General.getPricing()
                let data = null;
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    data = { ...req.data.data.attributes }
                    setPricing(data)
                } else throw new Error('Could not fetch PRICING')
            } catch (error) {
                console.log('Err : ', error);
            }
        };

        if (!pricing) fetchPricing();
    })

    // FETCH PLANS
    useEffect(() => {

        async function fetchPlans() {
            try {
                let req = await API.General.getPlans()
                const data = [];
                if (req && req.data && req.data.data && Array.isArray(req.data.data)) {
                    for (let plan of req.data.data) {
                        if (plan.id && plan.attributes && plan.attributes.name) {
                            data.push({ id: plan.id, ...plan.attributes })
                        }
                    }
                } else throw new Error('Could not fetch PRICING')

                if (data) setPlans(data)
            } catch (error) {
                console.log('Err : ', error);
            }
        };

        if (!plans) fetchPlans();
    }, [setPlans, plans])




    return (
        <div>
            <div className="mx-auto mt-12 max-w-7xl px-6 sm:mt-24 lg:px-8 relative">

                <svg
                    className="absolute inset-0 pointer-events-none h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
                </svg>
                <svg
                    viewBox="0 0 1108 632"
                    aria-hidden="true"
                    className="absolute pointer-events-none top-52 left-[calc(50%-4rem)]  w-[69.25rem] max-w-none transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                >
                    <path
                        fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
                        fillOpacity=".2"
                        d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z"
                    />
                    <defs>
                        <linearGradient
                            id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
                            x1="1220.59"
                            x2="-85.053"
                            y1="432.766"
                            y2="638.714"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#4F46E5" />
                            <stop offset={1} stopColor="#80CAFF" />
                        </linearGradient>
                    </defs>
                </svg>

                <div className="flex w-full px-4 mb-16 justify-around items-center  my-16 md:my-32">
                    <h1 className="text-5xl md:text-7xl text-[#01B5CC] font-bold font-get_schwifty tracking-widest"
                        style={{ textShadow: "rgb(178, 224, 40) -1px -1px 0px, rgb(178, 224, 40) 1px -1px 0px, rgb(178, 224, 40) -1px 1px 0px, rgb(178, 224, 40) 1px 1px 0px" }}
                    >
                        Pricing
                    </h1>
                </div>

                <div className="mx-auto max-w-4xl text-center">
                    {pricing ? <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
                        {pricing.title}
                    </p> : <Skeleton baseColor='#414141' className="h-7 my-2" width="260px" />}
                </div>

                {pricing ? <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
                    {pricing.description}
                </p> : <Skeleton baseColor='#414141' count={5} className="h-3" width="300px" />}
                <div className="mt-16 flex justify-center">
                    <RadioGroup
                        value={frequencyMonthly}
                        onClick={() => {
                            setFrequencyMonthly(!frequencyMonthly)
                        }}
                        className="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white relative"
                    >
                        <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                        <RadioGroup.Option
                            key={'monthly'}
                            value={frequencyMonthly}
                            className={() =>
                                classNames(frequencyMonthly ? 'bg-green-portal' : '', 'cursor-pointer rounded-full py-1 px-2.5')
                            }
                        >
                            <span>Monthly</span>
                        </RadioGroup.Option>
                        <RadioGroup.Option
                            key={'annually'}
                            value={!frequencyMonthly}
                            className={() =>
                                classNames(!frequencyMonthly ? 'bg-green-portal' : '', 'cursor-pointer rounded-full py-1 px-2.5 ')
                            }
                        >
                            <span>Annually</span>
                        </RadioGroup.Option>
                        <span className={(!frequencyMonthly ? "text-green-portal" : " text-gray-500 ") + " absolute text-sm w-20 font-extrabold -top-4 left-[75%] rotate-[28deg] leading-[13px]"}>2 months FREE</span>

                    </RadioGroup>
                </div>

                <div className="isolate mx-auto mt-10 md:mt-20 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 ">

                    {(plans ? plans : [
                        { id: 1, mostPopular: false },
                        { id: 2, mostPopular: true },
                        { id: 3, mostPopular: false },
                    ]).map((plan) => (
                        <div
                            key={plan.id}
                            className={classNames(
                                plan.mostPopular ? 'bg-white/5 ring-2 ring-green-portal' : 'ring-1 ring-white/10',
                                'rounded-3xl p-8 xl:p-10'
                            )}
                        >
                            <div className="flex items-center justify-between gap-x-4">

                                {plans ? <h2 id={plan.id} className="text-lg font-semibold leading-8 text-white">
                                    {plan.name}
                                </h2> : <Skeleton baseColor='#414141' className="h-5 my-2" width="260px" />}

                                {plans && plan.mostPopular &&
                                    <p className="rounded-full bg-green-portal py-1 px-2.5 text-xs font-semibold leading-5 text-white">
                                        Most popular
                                    </p>
                                }
                            </div>

                            {plans ?
                                <Markdown content={plan.description} className="mt-4 text-sm text-left leading-6 text-gray-300" />
                                : <Skeleton baseColor='#414141' count={2} className="h-2 my-2" width="260px" />}

                            {plans ? <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-white">{(plan.price * (frequencyMonthly ? 1 : 10)).toFixed(2)}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-300">$ {frequencyMonthly ? '/ month' : '/ year'}</span>
                            </p> : <Skeleton baseColor='#414141' className="h-4 my-1" width="160px" />}

                            {plans
                                ?
                                (userInfo && userInfo.plan && userInfo.plan === plan.id)
                                    ?
                                    <span className="mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white border-2">Your actual plan</span>
                                    :
                                    <NavLink
                                        to={plan.price === 0 ? '/authentification' : `/checkout/${plan.id}?freq=${frequencyMonthly ? 'monthly' : 'annually'}`}
                                        className={classNames(
                                            plan.mostPopular
                                                ? 'bg-green-portal text-white shadow-sm hover:bg-green-portal-light focus-visible:outline-green-portal'
                                                : 'bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white',
                                            'mt-6 cursor-pointer block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                        )}
                                    >
                                        {plan.CTA}
                                    </NavLink>
                                :
                                <Skeleton baseColor='#414141' className="h-7 my-2" width="200px" />
                            }

                            <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">

                                {plans ? featuresKeys.map((feature) => {

                                    const uniqueKey = plan.id.toString() + feature.key
                                    const value = plan[feature.key] !== "-1" ? plan[feature.key] : "Ꝏ"

                                    return (
                                        <li key={uniqueKey} className="flex gap-x-3">
                                            <FontAwesomeIcon icon={faCheck} className="h-6 w-5 flex-none text-white" aria-hidden="true" />
                                            <span className='  font-semibold'>{value}</span>
                                            {feature.label}
                                        </li>
                                    )
                                }) : <Skeleton baseColor='#414141' className="h-3 my-1" width="260px" />}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>


            {/* FAQ section */}
            <div className="mx-auto mt-24 max-w-7xl px-6 sm:mt-56 lg:px-8 mb-16 text-left ">

                {faq ? <h2 className="text-2xl font-bold leading-10 tracking-tight text-green-portal">{faq.title}</h2> : <Skeleton baseColor='#414141' className="h-6 my-2" width="260px" />}
                {faq ? <Markdown className="mt-6 max-w-2xl text-base leading-7 text-gray-300" content={faq.description} /> : <Skeleton baseColor='#414141' count={4} className="h-3 my-1" width="260px" />}

                <div className="mt-20 text-left relative">
                    <img style={{ transform: 'scaleX(-1)' }} src={rick} alt="rick" className="absolute z-0 right-0 bottom-full h-44 w-auto border-none hidden md:flex opacity-90" />
                    <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">

                        {faq ? faq.qAnda.map((f) => (
                            <div key={f.id}>
                                <dt className="text-base font-semibold leading-7 text-white">{f.question}</dt>
                                <dd className="mt-2 text-base leading-7 text-gray-300">{f.answer}</dd>
                            </div>
                        )) : [1, 2, 3, 4, 5, 6].map(nb => <div key={nb}>
                            <Skeleton baseColor='#414141' className="h-5 my-3" width="200px" />
                            <Skeleton baseColor='#414141' count={3} className="h-3" width="260px" />
                        </div>)}

                    </dl>
                </div>
            </div>

            <svg
                viewBox="0 0 1024 1024"
                className="absolute -bottom-0 left-1/2 h-[64rem] w-[64rem] -translate-x-1/2 opacity-30"
                aria-hidden="true"
            >
                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                <defs>
                    <radialGradient
                        id="759c1415-0410-454c-8f7c-9a820de03641"
                        cx={0}
                        cy={0}
                        r={1}
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(512 512) rotate(90) scale(512)"
                    >
                        <stop stopColor="#4F46E5" />
                        <stop offset={1} stopColor="#80CAFF" stopOpacity={0} />
                    </radialGradient>
                </defs>
            </svg>
        </div>
    )

}

export default PricingPage