import React, { useRef } from "react";

import gsap from "gsap";
import toast from "react-hot-toast";
import { Float } from "@react-three/drei";

import TextCustom from "../../../../../../components/THREEJS/TextCustom";
import { degToRad } from "three/src/math/MathUtils";

import * as THREE from 'three'
import { useLoader } from "react-three-fiber";


const CopyIcon = (props) => {

    const { url } = props

    let preventDoubleClick = false

    const copyIconRef = useRef()

    const texture = useLoader(THREE.TextureLoader, '/img/copy-link-512.png')


    const animateCopyLinkOnClick = () => {
        if (copyIconRef.current && !preventDoubleClick) {

            preventDoubleClick = true

            const timer = setTimeout(() => preventDoubleClick = false, 1000);

            navigator.clipboard.writeText(url)
            toast.success(`URL copied to clipdoard`)

            const boxTl = gsap.timeline();

            const coord = { x: 0, y: degToRad(360), z: 0 }
            const base = { x: degToRad(0), y: degToRad(0), z: degToRad(0) }

            boxTl.to(copyIconRef.current.rotation, { duration: .6, ease: "power4.out", ...coord })
            boxTl.to(copyIconRef.current.rotation, { duration: .4, ease: "power4.in", ...base }, '>')

            return () => {
                clearTimeout(timer);
            };
        }
    }

    return <Float floatIntensity={5} rotationIntensity={0} speed={2}>
        <group onClick={() => animateCopyLinkOnClick()}>

            {/* ICON LOGO FILE*/}
            <mesh ref={copyIconRef} position={[0, 7, 0]} scale={1}>
                <planeBufferGeometry attach={"geometry"} args={[10, 10, 10]} />
                <meshPhongMaterial attach={"material"} color={"white"} map={texture} transparent={true} emissive={"white"} opacity={1} emissiveIntensity={.01} />
            </mesh>

            {/* TEXT  */}
            <group>
                <TextCustom color={"#ffffff"} position={[0, -.6, .2]} text={'copy'} params={{ size: 1.5, height: .01, curveSegments: 64, }} />
                <TextCustom color={"#ffffff"} position={[0, -2.2, .2]} text={'link'} params={{ size: 1.5, height: .01, curveSegments: 64, }} />
            </group>

        </group>
    </Float>
}

export default CopyIcon;