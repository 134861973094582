import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import toast from 'react-hot-toast'

import API from '../../../api/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faDotCircle, faEnvelope, faEyeLowVision, faInfinity, faPenClip } from '@fortawesome/free-solid-svg-icons'

import { useAuth } from '../../../context/AuthContext'
import { useStaticData } from '../../../context/StaticDataContext'
import { getFullImageUrl } from '../../../helpers/functions'


const Profile = (props) => {

    const { userInfo, logout } = useAuth()

    const { profileInfo, setProfileInfo } = useStaticData()

    const [editMode, setEditMode] = useState(false)

    // change password
    const [passwordCurr, setPasswordCurr] = useState("")
    const [passwordNew, setPasswordNew] = useState("")
    const [passwordConf, setPasswordConf] = useState("")
    const [submitChangePasswordAllowed, setSubmitChangePasswordAllowed] = useState(false)

    useEffect(() => {
        if (userInfo && userInfo.id && !profileInfo) {
            API.Account.getProfileInfo().then(res => {
                if (res && res.data && res.data.infos) {
                    setProfileInfo(res.data.infos)
                }
            })
        }
    }, [userInfo, setProfileInfo])

    // update submitChangePasswordAllowed value on each input change
    useEffect(() => {

        let newSCPA = true;
        newSCPA = newSCPA && passwordCurr.length > 0
        newSCPA = newSCPA && passwordNew.length > 0
        newSCPA = newSCPA && passwordConf.length > 0
        newSCPA = newSCPA && passwordConf === passwordNew

        if (newSCPA !== submitChangePasswordAllowed) setSubmitChangePasswordAllowed(newSCPA)


    }, [passwordCurr, passwordConf, passwordNew, setSubmitChangePasswordAllowed])

    const changePasswordHanlder = () => {

        if (!submitChangePasswordAllowed) return toast.error('invalid inputs, please check fields')

        const data = {
            currentPassword: passwordCurr,
            password: passwordNew,
            passwordConfirmation: passwordConf
        }

        API.Account.updatePassword(data).then(res => {
            toast.success("Password changed !")
        }).catch(err => {
            console.log('err', err);
        }).finally(() => {
            resetStateAndLeave()
        })


    }

    const resetStateAndLeave = () => {
        setPasswordCurr("")
        setPasswordNew("")
        setPasswordConf("")
        setSubmitChangePasswordAllowed(false)
        setEditMode(false)
    }

    return (
        <div className="py-10 z-10 relative">

            {/* Page header */}
            <div className="  mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            <img
                                className="h-16 w-16 rounded-full opacity-70"
                                // src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                src="/img/default-user.png"
                                alt="default user image"
                            />
                            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                        </div>
                    </div>
                    <div className='text-left'>
                        <h1 className="text-3xl font-bold text-gray-50">
                            {userInfo.username}
                        </h1>
                        <p className="text-base font-medium text-gray-400">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
                            {userInfo.email}
                        </p>
                    </div>
                </div>
                <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                    <button
                        onClick={() => setEditMode(!editMode)}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-gray-900 bg-opacity-40 px-3 py-2 text-sm font-semibold text-gray-200 hover:text-white shadow-sm  "
                    >
                        {!editMode ? 'Change password' : 'View profile'}
                        {!editMode && <FontAwesomeIcon icon={faPenClip} className="ml-2" />}
                    </button>
                    {/* <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-red-600 opacity-80 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                        Advance to offer
                    </button> */}
                    <button onClick={logout} className=" bg-opacity-40 hover:bg-opacity-60  inline-flex items-center justify-center rounded-md bg-red-600 px-2 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"          >
                        Log out
                        <FontAwesomeIcon icon={faArrowRightFromBracket} className="ml-2" />
                    </button>
                </div>

            </div>

            <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-span-2 lg:col-start-1">

                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-gray-900 bg-opacity-60 shadow sm:rounded-lg text-left">
                            <div className={(editMode && "flex justify-between items-center") + " px-4 py-5 sm:px-6 relative"}>

                                {(!editMode && profileInfo) && <img
                                    className="h-12 w-12 rounded-full absolute right-4 top-1/2 -translate-y-1/2 z-20 bg-white p-1"
                                    src={getFullImageUrl(profileInfo.plan.image.url)}
                                    alt="plan image"
                                />}
                                {profileInfo &&

                                    <h2 id="applicant-information-title" className="text-lg font-semibold leading-6 text-gray-50 tracking-wider">
                                        {editMode
                                            ? <span>Change password<FontAwesomeIcon icon={faPenClip} className="ml-2 text-base" /></span>
                                            : <span> {profileInfo.plan.name}</span>}

                                    </h2>
                                }

                                {!editMode && <p className="mt-1 max-w-2xl text-sm text-gray-400">My plan's details</p>}

                                {editMode && <div className=" text-right">

                                    <button
                                        onClick={resetStateAndLeave}
                                        className="inline-flex justify-center opacity-75 mr-4 rounded-md bg-red-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
                                    >Cancel</button>

                                    <button
                                        onClick={changePasswordHanlder}
                                        className="inline-flex justify-center rounded-md bg-green-portal py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-green-portal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
                                    >Save</button>

                                </div>}
                            </div>

                            {editMode
                                ? <div className="mt-10 sm:mt-0 pb-4">
                                    <div className="md:grid md:grid-cols-3 md:gap-6">

                                        <div className="mt-5 col-span-6 md:mt-0">
                                            <div className="overflow-hidden shadow sm:rounded-md">
                                                <div className=" px-4 py-5 sm:p-6">
                                                    <div className="grid grid-cols-6 gap-6">
                                                        {/* <div className="col-span-6 sm:col-span-3">
                                                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-50">
                                                                First name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="first-name"
                                                                id="first-name"
                                                                autoComplete="given-name"
                                                                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-50">
                                                                Last name
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="last-name"
                                                                id="last-name"
                                                                autoComplete="family-name"
                                                                className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            />
                                                        </div> */}

                                                        <PasswordInputToggle value={passwordCurr} title="Current password" setValue={(v) => setPasswordCurr(v)} />
                                                        <div className="col-span-6 sm:col-span-3">   </div>
                                                        <PasswordInputToggle value={passwordNew} title="New password" setValue={(v) => setPasswordNew(v)} />
                                                        <PasswordInputToggle value={passwordConf} title="Confirm password" setValue={(v) => setPasswordConf(v)} />
                                                        {/* <UpdateProfiledata /> */}

                                                        {/* <div className="col-span-6 sm:col-span-4">
                                                                <label htmlFor="email-address" className="block text-sm font-medium leading-6 text-gray-50">
                                                                    Email address
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="email-address"
                                                                    id="email-address"
                                                                    autoComplete="email"
                                                                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-3">
                                                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-50">
                                                                    Country
                                                                </label>
                                                                <select
                                                                    id="country"
                                                                    name="country"
                                                                    autoComplete="country-name"
                                                                    className="mt-2 block w-full rounded-md border-0 bg-white py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                >
                                                                    <option>United States</option>
                                                                    <option>Canada</option>
                                                                    <option>Mexico</option>
                                                                </select>
                                                            </div>

                                                            <div className="col-span-6">
                                                                <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-50">
                                                                    Street address
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="street-address"
                                                                    id="street-address"
                                                                    autoComplete="street-address"
                                                                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                                <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-50">
                                                                    City
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="city"
                                                                    id="city"
                                                                    autoComplete="address-level2"
                                                                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-50">
                                                                    State / Province
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="region"
                                                                    id="region"
                                                                    autoComplete="address-level1"
                                                                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div>

                                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                                <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-50">
                                                                    ZIP / Postal code
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    name="postal-code"
                                                                    id="postal-code"
                                                                    autoComplete="postal-code"
                                                                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                />
                                                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    {profileInfo && <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-400">{profileInfo.sessionLeftD.label}</dt>
                                            <dd className="mt-1 text-base text-gray-50">{profileInfo.sessionLeftD.used} <span className='text-gray-200 text-xs'>/ {profileInfo.sessionLeftD.max === -1 ? <FontAwesomeIcon icon={faInfinity} /> : profileInfo.sessionLeftD.max}</span></dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-400">{profileInfo.sessionLeftM.label}</dt>
                                            <dd className="mt-1 text-base text-gray-50">{profileInfo.sessionLeftM.used} <span className='text-gray-200 text-xs'>/ {profileInfo.sessionLeftM.max === -1 ? <FontAwesomeIcon icon={faInfinity} /> : profileInfo.sessionLeftM.max}</span></dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-400">Max file size</dt>
                                            <dd className="mt-1 text-sm text-gray-50">{profileInfo.plan.maxFileSizeMB} Mb</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-400">Total size /session</dt>
                                            <dd className="mt-1 text-sm text-gray-50">{profileInfo.plan.maxMOPerSession} Mb</dd>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-400">About</dt>
                                            <dd className="mt-1 text-sm text-gray-300">
                                                {profileInfo.plan.description}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-2 flex justify-end">
                                            <NavLink
                                                to={'/pricing'}
                                                className="inline-flex items-center justify-center rounded-md bg-green-portal px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-900 hover:bg-green-portal"
                                            >
                                                Our plans
                                            </NavLink>
                                        </div>
                                    </dl>}
                                </div>}

                        </div>
                    </section>
                </div>

                <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
                    <div className="bg-gray-900 bg-opacity-60 px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <h2 id="timeline-title" className="text-lg font-medium text-gray-50">
                            History
                        </h2>

                        {/* Activity Feed */}
                        <div className="mt-6 flow-root">
                            {profileInfo && <ul role="list" className="-mb-8">
                                {profileInfo.history.map((item, itemIdx) => (
                                    <li key={item.id}>
                                        <div className="relative pb-8">
                                            {itemIdx !== profileInfo.history.length - 1 ? (
                                                <span
                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-700"
                                                    aria-hidden="true"
                                                />
                                            ) : null}
                                            <div className="relative flex space-x-3">
                                                <div>
                                                    <span
                                                        className='h-8 w-8 rounded-full flex items-center justify-center'
                                                    >
                                                        <FontAwesomeIcon icon={faDotCircle} className="text-gray-300" />
                                                    </span>

                                                </div>
                                                <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                                    <div>
                                                        <p className="text-sm text-gray-400">
                                                            Dimension <span className='text-gray-100 mr-1'>{item.slug}</span>{' '}-{' '}<span className='text-gray-200 ml-1'>{item.itemCount}</span> {`file${item.itemCount > 1 ? 's' : ''}`} exchanged
                                                        </p>
                                                    </div>
                                                    <div className="whitespace-nowrap text-right text-sm text-gray-400">
                                                        <time >{item.date}</time>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>}
                        </div>
                        <div className='flex flex-col text-gray-300'>
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </div>
                    </div>
                </section>
            </div>
        </div>


    )
}

export const PasswordInputToggle = (props) => {

    const { value, setValue, title } = props

    const [show, setShow] = useState(false)

    return <>
        <div className="col-span-6 sm:col-span-3">
            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-50">
                {title}
            </label>
            <div className="mt-1 w-full mb-3 relative">
                <input
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    maxLength={10}
                    type={show ? "test" : "password"}
                    className="mt-2 block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <FontAwesomeIcon className='cursor-pointer absolute right-2 text-gray-600 -translate-y-1/2 top-1/2' onClick={() => setShow(!show)} icon={faEyeLowVision} />
            </div>
        </div>
    </>
}




export default Profile