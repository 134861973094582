import React from "react";
import { NavLink } from "react-router-dom";

import './CTASection.css'

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import owee from '../../../../../images/owee.png'
import { usePrompt } from "../../../../../context/PromptContext";

const CTASection = (props) => {

    const { setPrompt } = usePrompt()

    return (
        <div className="bg-transparent relative">
            <div id="owee" onClick={() => { setPrompt(['oooooowwweeeeeeeeeeeeeeeeeeeeeeee']) }} className="cursor-pointer">
                <img alt='oweeee' src={owee} className="animate-spin-slow h-24 w-auto" />
            </div>
            <div className="z-10 mx-auto max-w-7xl text-left py-24 sm:py-32 lg:flex lg:items-center lg:justify-between px-6 lg:px-8">
                <h2 className="text-3xl font-bold tracking-tight text-gray-50 sm:text-4xl">
                    Ready to dive in?
                    <br />
                    Start your free trial today.
                </h2>
                <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                    <NavLink
                        to={'/pricing'}
                        className="rounded-md cursor-pointer bg-green-portal px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-portal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
                    >
                        Start FREE Trial
                    </NavLink>
                    <NavLink to={'/features'} className="text-sm font-semibold leading-6 text-gray-50">
                        Learn more <FontAwesomeIcon icon={faArrowRight} />
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default CTASection;