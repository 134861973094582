import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

import FeaturesSection from './FeaturesSection'
import Markdown from '../../../../components/HTML/Markdown'

import API from '../../../../api/index'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import chrome from '../../../../images/chrome_icon.png'
import { useStaticData } from '../../../../context/StaticDataContext'
import { getFullImageUrl } from '../../../../helpers/functions'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import '../../LandingPage.css'

const HeroSection = (props) => {

    const { scrollUpThenStart } = props

    const { service, setService } = useStaticData()

    useEffect(() => {
        async function fetchService() {
            try {
                let req = await API.General.getService()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes }
                    const appDemoLargeMedia = obj.appDemoLarge && obj.appDemoLarge.data && obj.appDemoLarge.data.attributes ? getFullImageUrl(obj.appDemoLarge.data.attributes.url) : null
                    obj.appDemoLarge = appDemoLargeMedia
                    setService(obj)
                } else throw new Error('Could not fetch SERVICES')
            } catch (error) {
                console.log('Err : ', error);
            }
        };
        if (!service) fetchService();
    }, [service, setService])

    return (<>
        <div className='h-12  sm:h-20 lg:h-28 w-full pointer-events-none'></div>
        <div className="relative isolate">

            <svg
                viewBox="0 0 1108 632"
                aria-hidden="true"
                className="absolute top-0 left-0 -z-10 w-[69.25rem] max-w-none transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-12 lg:-top-12 xl:left-[calc(50%-24rem)]"
            >
                <path
                    fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
                    fillOpacity=".2"
                    d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z"
                />
                <defs>
                    <linearGradient
                        id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
                        x1="1220.59"
                        x2="-85.053"
                        y1="432.766"
                        y2="638.714"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#4F46E5" />
                        <stop offset={1} stopColor="#80CAFF" />
                    </linearGradient>
                </defs>
            </svg>
            <div className="mx-auto max-w-7xl px-3 pb-12 sm:pb-20 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:pb-28 md:text-left">

                <div className="mx-auto max-w-3xl lg:mx-0 lg:flex-auto">
                    {service && service.pluginLink && <div className="flex">
                        <div className="relative flex items-center gap-x-2 rounded-full  pr-4 text-sm leading-6 text-gray-300 ring-1 ring-gray-50/10 hover:ring-gray-50/20">
                            <img src={chrome} alt="chrome icon" className="h-8 w-auto" />
                            <span className="font-semibold text-green-portal">Plug-In available</span>
                            <span className="h-4 w-px bg-gray-600" aria-hidden="true" />
                            {service ? <a target="_blank" rel="noreferrer"
                                href={service.pluginLink}
                                className="flex items-center gap-x-1">
                                <span className="absolute inset-0" aria-hidden="true" />
                                Install now
                                <FontAwesomeIcon icon={faChevronRight} className="-mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                            </a> : <div className='w-28 h-6'>
                                <Skeleton baseColor='#414141' />
                            </div>}
                        </div>
                    </div>}
                    {service
                        ? <h1 className="mt-10 max-w-xl text-4xl font-bold tracking-tight text-gray-50 sm:text-6xl">{service.title}</h1>
                        : <div className='my-4'><Skeleton count={1} baseColor='#414141' className='my-1 h-10' /></div>}

                    {service
                        ? <Markdown content={service.description} className="mt-6 text-lg leading-8 text-gray-300" />
                        : <div><Skeleton baseColor='#414141' count={4} width='90%' /></div>
                    }

                    <div className="mt-10 flex items-center gap-x-6">
                        <div
                            onClick={scrollUpThenStart}
                            className="rounded-md bg-green-portal px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-portal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
                        >
                            Get started
                        </div>
                        <NavLink to={"/features"} className="text-sm font-semibold leading-6 text-gray-50">
                            Learn more <FontAwesomeIcon icon={faArrowRight} />
                        </NavLink>
                    </div>
                </div>

                <div className="h-72 md:h-96 w-full lg:w-2/5 mx-auto min-w-[280px] rounded-full overflow-hidden hidden lg:flex opacityFadeIn">
                    <img className="h-full w-auto anime-float" src='/img/logo512.png' alt="logo" />
                </div>
            </div>
            {/* FEATURES SECTION  */}
            <FeaturesSection service={service} />
        </div>
    </>

    )
}
export default HeroSection;