import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import toast from 'react-hot-toast';

// ANIMATIONS
import gsap from 'gsap';

// API
import API from '../../../api/index'

// CUSTOM COMPONENTS
import FileItem from '../../../components/FileItem';

// UTILS
import { degToRad } from 'three/src/math/MathUtils';


const FileSender = forwardRef((props, ref) => {

    const { sessionAccess, socket, setPrompt, planDetails } = props

    const fileRef = useRef()

    const [file, setFile] = useState(null)
    const [usedMO, setUsedMO] = useState(0)

    // SEND BACK FUNTIONS TO PARENT
    useImperativeHandle(ref, () => ({
        handleChange(e) {
            handleChange(e)
        },
        incrementUsedMO(f) {
            incrementUsedMO(f)
        }
    }));

    const shortName = (name) => {
        if (name) {
            let newName = []
            const nameArr = name.split('')
            nameArr.forEach((ch, i) => {
                if (i >= 14) return 0
                else newName.push(ch)
            })
            if (nameArr.length > 15) newName.push('..')
            return newName.join('')
        } else return 'N/a'
    }

    // ======================================================================================
    // ========= Files Gestion Related Functions

    // Handle new file Input OnChang & call handleSubmit
    const handleChange = (newFile) => {

        // one file only for now.....
        if (newFile) {

            const fileSize = newFile.size

            if (fileSize < planDetails.maxFileSizeMB * 1000000) {


                const reader = new FileReader();
                reader.readAsDataURL(newFile);
                reader.onloadend = function () {
                    setFile({ file: newFile, preview: reader.result })
                };

            } else setPrompt(['File too large.. upgrade your plan for larger uploads'])

        }

    }

    // Submit new file & emit Socket
    const handleSubmit = useCallback(async (event, files) => {
        if (event) event.preventDefault()

        if (usedMO >= planDetails.maxMOPerSession) {
            animateFileFallDown()
            return toast.error('Maximum space used for this session, please puchase our plan to upgrade your rights')
        } else {
            // one file only for now.....

            if (files && sessionAccess) {

                setPrompt(['Uploading..'])

                const formData = new FormData()

                const data = {
                    name: files.name,
                    session: sessionAccess.sessionId,
                    fromGuest: sessionAccess.userAccess === 'gstTkn',
                }

                formData.append('data', JSON.stringify(data));
                formData.append('files.file', files, files.name);

                API.SessionItems.uploadSessionItemWithFiles(formData).then(res => {

                    if (res && res.status === 200 && res.data) {

                        animateFileInPortal(() => {

                            socket.emit('new_session_item', { id: res.data.id, name: res.data.name, fromGuest: res.data.fromGuest, file: res.data.file }, (error) => {
                                if (error) {
                                    alert(error)
                                    // history.push('/');
                                }
                            });
                            incrementUsedMO(res.data.file)
                            setPrompt([`${shortName(res.data.name)} uploaded`])
                            setFile(null)
                        })

                    }
                }).catch((error) => {
                    API.General.log({ data: { content: { err: error } } })
                    console.log('Err : ', error);
                })

            } else {
                toast.error('Something went wrong...')
            }
        }

    }, [sessionAccess, setPrompt, socket, usedMO, planDetails])

    const incrementUsedMO = (file) => {
        const { size } = file
        setUsedMO(usedMO => (usedMO + (size / 1000)))
    }

    // ======================================================================================
    // ========= Gsap Animations

    const animateFilePop = (callback) => {

        if (fileRef.current) {
            const TL = gsap.timeline({ onComplete: () => callback() })

            TL.to(fileRef.current, { duration: .3, ease: 'power1.out', visible: true })
            TL.to(fileRef.current.scale, { duration: .3, ease: 'power1.out', x: 1, y: 1, z: 1 }, '<')
        }

    }

    const animateFileInPortal = (callback) => {

        if (fileRef.current) {
            const TL = gsap.timeline({ onComplete: () => callback() })

            TL.to(fileRef.current.position, { duration: .7, ease: 'power4.in', x: 0, y: 8, z: -6 })
            TL.to(fileRef.current.scale, { duration: .7, ease: 'power4.in', x: 0, y: 0, z: 0 }, '<')
            TL.to(fileRef.current.rotation, { duration: .6, ease: 'power4.in', x: -degToRad(60) }, '<-.1')

        }
    }

    const animateFileFallDown = () => {

        if (fileRef.current) {
            const TL = gsap.timeline()

            TL.to(fileRef.current.position, { duration: .7, ease: 'power4.in', x: 5, y: -10, z: 5 })
            TL.to(fileRef.current.scale, { duration: 1, ease: 'power4.in', x: 0, y: 0, z: 0 }, '<')
            TL.to(fileRef.current.rotation, { duration: .6, ease: 'power4.in', z: degToRad(20) }, '<-.1')

        }
    }

    useEffect(() => {
        if (file) animateFilePop(() => handleSubmit(null, file.file))
    }, [file, handleSubmit])

    return <mesh key={file ? file.file : null} visible={false} scale={[0, 0, 0]} ref={fileRef} position={[2, 5, 7]}>
        {file &&
            <FileItem name={file.file.name} ext={file.file.name.split('.')[file.file.name.split('.').length - 1]} url={file.preview} b64={true} textColor={"#000000"} />
        }
    </mesh>

})

export default FileSender