import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";



const NotFoundPage = () => {

    return (
        <main className="grid min-h-[73vh] h-full place-items-center  py-24 px-6 sm:py-32 lg:px-8">
            <div className="text-center">
                <p className="text-base font-semibold text-green-portal">404 Not Found</p>
                <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-50 sm:text-3xl">Oops ! <br></br>You are not supposed to be here..</h1>
                <img className="mx-auto" src='/img/morty-404.png' alt='morty-404' />
                {/* <p className="mb-6 text-base leading-7 text-gray-200">Go AWAY !</p> */}
            </div>
            <NavLink
                to={"/"}
                className="rounded-md bg-green-portal px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-portal focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal"
            >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-1" />
                Back Home
            </NavLink>
        </main>
    )
}

export default NotFoundPage;