import React, { useEffect } from "react";

import API from '../../api/index'

import Stats from "./Stats";
import LogoCloud from "./LogoCloud";
import Markdown from '../../components/HTML/Markdown'

import { getFullImageUrl } from "../../helpers/functions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { NavLink } from "react-router-dom";
import { useStaticData } from "../../context/StaticDataContext";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const FeaturesPage = () => {

    const { service, setService } = useStaticData()

    useEffect(() => {
        async function fetchService() {
            try {
                let req = await API.General.getService()
                if (req && req.data && req.data.data && req.data.data.attributes) {
                    const obj = { ...req.data.data.attributes }
                    const appDemoLargeMedia = obj.appDemoLarge && obj.appDemoLarge.data && obj.appDemoLarge.data.attributes ? getFullImageUrl(obj.appDemoLarge.data.attributes.url) : null
                    obj.appDemoLarge = appDemoLargeMedia
                    setService(obj)
                } else throw new Error('Could not fetch SERVICES')
            } catch (error) {
                console.log('Err : ', error);
            }
        };
        if (!service) fetchService();
    }, [service, setService])

    return (
        <>
            {/* Hero section */}
            <div className="relative isolate overflow-hidden">
                <svg
                    viewBox="0 0 1108 632"
                    aria-hidden="true"
                    className="absolute top-10 left-[calc(50%-4rem)] -z-10 w-[69.25rem] max-w-none transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
                >
                    <path
                        fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)"
                        fillOpacity=".2"
                        d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z"
                    />
                    <defs>
                        <linearGradient
                            id="175c433f-44f6-4d59-93f0-c5c51ad5566d"
                            x1="1220.59"
                            x2="-85.053"
                            y1="432.766"
                            y2="638.714"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#4F46E5" />
                            <stop offset={1} stopColor="#80CAFF" />
                        </linearGradient>
                    </defs>
                </svg>

                {/* BIG TITLE */}
                <div className="flex w-full px-4 py-6 justify-around items-center  mt-12 md:mt-28">
                    <h1
                        className="text-4xl md:text-6xl text-[#01B5CC] font-bold font-get_schwifty tracking-widest "
                        style={{ textShadow: "rgb(178, 224, 40) -1px -1px 0px, rgb(178, 224, 40) 1px -1px 0px, rgb(178, 224, 40) -1px 1px 0px, rgb(178, 224, 40) 1px 1px 0px" }}>
                        Features
                    </h1>
                </div>

                <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-40 lg:flex lg:justify-around lg:px-8 lg:pt-12">
                    <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8 mt-24 sm:mt-32 lg:mt-16">
                        {service && service.pluginLink && <div className="">
                            {service ? <a target="_blank" rel="noreferrer" href={service.pluginLink} className="inline-flex space-x-6">
                                <span className="rounded-full bg-green-portal/10 px-3 py-1 text-sm font-semibold leading-6 text-green-portal-light ring-1 ring-inset ring-green-portal/20">
                                    Plug in Available
                                </span>
                                <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                                    <span>Install Now</span>
                                    <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                </span>
                            </a> : <Skeleton baseColor='#414141' className="h-4 my-3" width="160px" />}
                        </div>}
                        {service ? <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
                            {service.title}
                        </h1> : <Skeleton baseColor='#414141' className="h-7 my-4" width="360px" />}
                        {service ? <Markdown content={service.description} className="mt-6 text-lg leading-8 text-gray-300" /> : <Skeleton baseColor='#414141' count={5} className="h-4 my-2" width="360px" />}
                        <div className="mt-10 flex items-center gap-x-6">
                            <NavLink to={"/pricing"}
                                href="#"
                                className="rounded-md bg-green-portal px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-portal-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-portal-light"
                            >
                                Start FREE trial
                            </NavLink>
                            <NavLink to={"/#top"} className="text-sm font-semibold leading-6 text-white">
                                Try first <FontAwesomeIcon icon={faArrowRight} />
                            </NavLink>
                        </div>
                    </div>
                    {service ? (service.appDemoLarge && <div className="flex justify-center items-center p-8">
                        <img
                            src={service.appDemoLarge}
                            alt="Application screenshot"
                            // width={2432}
                            // height={1442}
                            className="w-full max-w-md"
                        />
                    </div>)
                        : <Skeleton baseColor='#414141' className="h-72" width="360px" />}
                    {/* <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
                        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                            <img
                                src={service.appDemoLarge}
                                alt="Application screenshot"
                                width={2432}
                                height={1442}
                                className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
                            />
                        </div>
                    </div> */}
                </div>
            </div>

            {/* Feature section */}
            <div className="mb-32 sm:mb-56 relative">

                <svg
                    className="absolute inset-0  h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="1d4240dd-898f-445f-932d-e2872fd12de3"
                            width={200}
                            height={200}
                            x="50%"
                            y={0}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={0} className="overflow-visible fill-gray-800/20">
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
                </svg>

                {/* APP STROY */}
                <div className="mx-auto max-w-7xl px-6 lg:px-8 mb-10 md:mb-44">
                    <div className="mx-auto max-w-2xl sm:text-center">
                        {service ? <h2 className="text-base font-semibold leading-7 text-green-portal">{service.longDescSubTitle}</h2> : <Skeleton baseColor='#414141' className="h-4 my-3" width="160px" />}
                        {service ? <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">{service.longDescTitle}</p> : <Skeleton baseColor='#414141' className="h-7 my-3" width="360px" />}
                        {service ? <Markdown content={service.longDescBody} className="mt-6 text-lg leading-8 text-gray-300" /> : <Skeleton baseColor='#414141' count={7} className="h-4 my-3" width="360px" />}
                    </div>
                </div>


                {/* CLOUD LOGO TECHNO USED */}
                <LogoCloud />

                {/* FEATURES */}
                <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-10 md:mt-12 lg:px-8">
                    <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                        {service ? service.features.map((feature) => {
                            return (
                                <div key={feature.id} className="relative pl-9">
                                    <dt className="inline font-semibold text-white">
                                        {feature.title}{'.'}
                                    </dt>{' '}
                                    <dd className="inline">{feature.description}</dd>
                                </div>
                            )
                        }) :
                            [1, 2, 3, 4, 5, 6].map(nb => <div key={nb}>
                                <Skeleton baseColor='#414141' className="h-5 my-3" width="200px" />
                                <Skeleton baseColor='#414141' count={3} className="h-3" width="260px" />
                            </div>
                            )
                        }
                    </dl>
                </div>


            </div>

            {/* Stats */}
            <Stats />

        </>
    )

}

export default FeaturesPage