import React, { useRef } from 'react';

import { PromptContextProvider } from '../../../context/PromptContext'
import { AuthContextProvider } from '../../../context/AuthContext'
import { StaticDataProvider } from '../../../context/StaticDataContext';

import Terminal from '../Terminal/Terminal';
import Footer from '../Footer';
import Header from '../Header'

import './Wrapper.css'

const Wrapper = (props) => {

    const terminalRef = useRef();
    const headerRef = useRef();

    const clearTerminal = () => terminalRef.current.clearTerminal()
    const setPrompt = (prompt) => terminalRef.current.setPrompt(prompt)

    const initDimensionTimer = (timeinMin) => headerRef.current.initDimensionTimer(timeinMin)

    return <div className="flex justify-start flex-col items-center w-full relative bg-black h-full min-h-screen">
        <div className='fixed bottom-0 left-0 w-fit h-fit z-50'>
            <Terminal ref={terminalRef} />
        </div>
        <PromptContextProvider initDimensionTimer={initDimensionTimer} setPrompt={setPrompt} clearTerminal={clearTerminal}>
            <StaticDataProvider>
                <AuthContextProvider>
                    <Header ref={headerRef} />
                    <main className='w-full h-full min-h-screen flex-1 -mt-[64px]'>
                        {props.children}
                    </main>
                    <Footer />
                </AuthContextProvider>
            </StaticDataProvider>
        </PromptContextProvider>
    </div>
}

export default Wrapper;
