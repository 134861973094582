import React, { useEffect } from "react";

import API from '../../api/index'
import { useStaticData } from "../../context/StaticDataContext";

import { getFullImageUrl } from "../../helpers/functions";

import rickWork from '../../images/rickWork.png'

const LogoCloud = () => {

    const { logoCloud, setLogoCloud } = useStaticData()

    useEffect(() => {
        async function fetchLogoCloud() {
            try {
                let req = await API.General.getLogoCloud()
                if (req && req.data) {
                    const obj = { ...req.data }
                    setLogoCloud(obj)
                } else throw new Error('Could not fetch LOGOCLOUD')
            } catch (error) {
                console.log('Err', error);
            }
        };
        if (!logoCloud) fetchLogoCloud();
    }, [logoCloud])

    return (
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8 relative">

            {logoCloud &&
                <h2 className="text-center text-2xl font-semibold leading-8 text-white">
                    {logoCloud.sectionTitle}
                </h2>
            }

            {logoCloud &&
                <div className=" border-t-2 border-gray-800/90 p-4 relative mx-auto mt-6 max-w-lg items-center flex flex-wrap sm:max-w-xl justify-around space-x-2 space-y-2  lg:mx-0 lg:max-w-none ">

                    <img src={rickWork} alt="rick working" className="absolute hidden sm:flex bottom-[88%] h-28 lg:h-48 w-auto left-0 md:left-6" />

                    {logoCloud.logos.map((logo, i) => (
                        <a key={logo.id} href={logo.link} target="_balnk" className="cursor-pointer transition-all duration-100 ease-in-out hover:scale-110">
                            <img
                                className="  max-h-16 w-full object-contain  mb-2 animate-pulse"
                                src={getFullImageUrl(logo.logo)}
                                alt={logo.alt}
                                width={158}
                                height={68}
                                style={{ animationDelay: `${i * 30}ms` }}
                            />
                            <span className="text-gray-200">{logo.alt}</span>
                        </a>
                    ))}

                </div>
            }
        </div>
    )

}

export default LogoCloud