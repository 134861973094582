import React, { useState, forwardRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faMinus, faMaximize } from '@fortawesome/free-solid-svg-icons'

import './OverlayComponent.css'
import LogIn from '../../../pages/Authentification/components/LogIn'

const OverlayComponent = forwardRef((props, ref) => {


    const { closeOverlay, fullScreen } = props

    const [fullScrOverlay, setFullScrOverlay] = useState(fullScreen)

    return <div style={{ width: '99vw' }} className={(fullScrOverlay ? ' h-screen w-full ' : ' !min-w-[360px] !w-1/2 h-4/5 right-0') + ' transition-all ease-in-out duration-150 fixed z-50 flex justify-center items-center  md:right-0'}>
        <div style={{ height: '95%' }} className={'flex bg-black bg-opacity-90 flex-col justify-start items-center text-white w-full m-3 rounded-md overflow-hidden border-white shadow-sm shadow-gray-700 animate__animated animate__fadeInDown'} >

            {/* HEADER  */}
            <div className=' bg-gray-900 h-10 max-h-10 w-full flex flex-row items-center justify-between px-3 relative' >
                {/* <span onClick={() => setShowOverlay(false)} className='rounded-full bg-red-700 h-4 w-4 cursor-pointer flex justify-center items-center'><FontAwesomeIcon className='text-gray-900' fontSize={12} icon={faTimes} /></span> */}
                <div>
                    {/* <div onClick={() => setShowOverlay(false)} className="inline-block w-3 h-3 mr-2 rounded-full bg-red-500 relative cursor-pointer iconOverlay_w">
                            <FontAwesomeIcon className='text-gray-900 absolute right-1/2 translate-x-1/2 ' fontSize={12} icon={faTimes} />
                        </div>
                        <div onClick={() => setFullScrOverlay(!fullScrOverlay)} className="inline-block w-3 h-3 mr-2 rounded-full relative bg-yellow-300 cursor-pointer iconOverlay_w">
                            <FontAwesomeIcon className='text-gray-900 absolute top-1/2 -translate-y-1/2 right-1/2 translate-x-1/2 ' fontSize={fullScrOverlay ? 12 : 9} icon={fullScrOverlay ? faMinus : faMaximize} />
                        </div> */}
                    {/* <div className="inline-block w-3 h-3 mr-2 rounded-full bg-green-portal-light">
                        </div> */}
                </div>
                {/* <span className='text-gray-100 absolute translate-x-1/2 right-1/2'>@{userInfo ? userInfo.username : "guest"}</span> */}
                {/* <span onClick={() => handleLogout()} className='text-gray-300 cursor-pointer hover:text-yellow-400 logout_wrapper'>{user ? <><span className='logout_label ease-in-out duration-200 transition-all '>logout</span> <FontAwesomeIcon className='rotate-180' icon={faSignOut} /> </> : ''}</span> */}
                <div className='flex justify-center items-center'>

                    <div onClick={() => setFullScrOverlay(!fullScrOverlay)} className="inline-block w-4 h-4 mr-2 rounded-full relative bg-yellow-300 cursor-pointer iconOverlay_w">
                        <FontAwesomeIcon className='text-gray-900 absolute top-1/2 font-extrabold -translate-y-1/2 right-1/2 translate-x-1/2 ' fontSize={fullScrOverlay ? 12 : 9} icon={fullScrOverlay ? faMinus : faMaximize} />
                    </div>
                    <div onClick={() => closeOverlay()} className="inline-block w-4 h-4  rounded-full bg-red-500 relative cursor-pointer iconOverlay_w">
                        <FontAwesomeIcon className='text-gray-900 absolute top-1/2 font-extrabold -translate-y-1/2 right-1/2 translate-x-1/2 ' fontSize={12} icon={faTimes} />
                    </div>
                    {/* <div className="inline-block w-3 h-3 mr-2 rounded-full bg-green-portal-light">
                        </div> */}
                </div>
            </div>

            {/* CONTENT */}
            <div className='w-full shadow-md shadow-gray-100 h-full overflow-y-scroll'>

                <LogIn loginCallBack={closeOverlay} />
            </div>

        </div>
    </div>

})

export default OverlayComponent