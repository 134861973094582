import React, { useEffect, useRef } from "react"

import gsap from "gsap"
import { Float } from "@react-three/drei"
import { useThree } from "react-three-fiber"

import TextCustom from "../../../../components/THREEJS/TextCustom"

import font from '../../../../fonts/Get Schwifty_Regular.json'

export default function ThreeTitle() {

    const { viewport } = useThree()
    const ref = useRef()

    useEffect(() => {
        if (ref && ref.current) {
            const TL = gsap.timeline()
            TL.to(ref.current.position, { delay: 1, duration: .5, ease: 'power2.out', z: 0 })
            TL.to(ref.current.scale, { duration: .5, ease: 'power2.out', x: 1, y: 1, z: 1 }, '<')
        }
    })

    return <mesh ref={ref} scale={0} position={[0, 0, -100]}>
        <Float scale={viewport.width > 18 ? 1 : .65} position={[0, 4.5, 0]} floatIntensity={1.5} rotationIntensity={0} speed={2}>
            <TextCustom color={"#70C61D"} text={'RICKTRANSFER'} font={font} params={{
                size: 1.2, height: .6, curveSegments: 64,

            }} />
        </Float>
    </mesh>
}