import React, { useEffect, useRef } from 'react'

import gsap from 'gsap';

import CopyIcon from './CopyIcon';
import InviteEmail from './InviteEmail';
import TextCustom from "../../../../../../components/THREEJS/TextCustom";

const ShareLinks = (props) => {

    const { url, mode, openMailPopUp } = props

    const linksRef = useRef()
    const textRef = useRef()

    useEffect(() => {
        if (linksRef.current) {
            const introTl = gsap.timeline();
            introTl.to(textRef.current.position, { duration: .8, ease: "power2.out", x: 0 })
            introTl.to(linksRef.current.position, { duration: 1.5, ease: "power2.out", y: -1 }, '<')
        }
    }, [])

    useEffect(() => {
        if (textRef.current && mode) {
            const introTl = gsap.timeline();
            const y = mode === 'mobile' ? -2.5 : -2.8
            introTl.to(textRef.current.position, { duration: 1.5, ease: "power2.out", y: y }, '<')
        }
    }, [mode])

    return <group>

        <group scale={1.1} ref={linksRef} position={[0, -20, 2]}>

            <mesh position={[-1.5, 0, 0]} scale={.18}>
                <CopyIcon url={url} />
            </mesh>

            <mesh position={[1.5, 0, 0]} scale={.18}>
                <InviteEmail openMailPopUp={openMailPopUp} />
            </mesh>
        </group>

        <mesh ref={textRef} position={[15, 0, 2]} >
            <TextCustom position={[0, -0.7, 0]} rotation={[-.3, 0, 0]} text={'SHARE'} params={{
                size: .6, height: .1, curveSegments: 64,
            }} />
        </mesh>

    </group>
}

export default ShareLinks;