import './App.css';
import 'animate.css';

import { useEffect } from 'react';
import { Route, Routes, HashRouter, Navigate } from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import { SocketContext, socket } from './context/socket';
import { CSSPlugin } from 'gsap/CSSPlugin'
import gsap from 'gsap'
import ReactGA from "react-ga4";
import useVH from "react-vh";


// import pages below
import LandingPage from './pages/LandingPage/LandingPage';
import Dimension from './pages/Dimension/Dimension';
import Wrapper from './components/HTML/Wrapper/Wrapper';
import FeaturesPage from './pages/FeaturesPage/FeaturesPage';
import PricingPage from './pages/PricingPage';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import ContactPage from './pages/ContactPage/ContactPage';
import ScrollToTop from './components/HTML/ScrollTop';
import CheckOutPage from './pages/CheckOutPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentErrorPage from './pages/PaymentErrorPage';
import EmailConfirmedPage from './pages/EmailConfirmedPage';
import AuthentificationPage from './pages/Authentification/AuthentificationPage';
import EmailVerify from './pages/EmailVerify';
import Privacypolicy from './pages/Privacypolicy';
import TermsOfUses from './pages/TermsOfUses';
import NotFoundPage from './pages/404Page';

function App() {

  ReactGA.initialize("G-KSSB6J4FNC");

  useEffect(() => {
    // Force CSSPlugin to not get dropped during build
    gsap.registerPlugin(CSSPlugin)



    // Make all Anchor link Scroll SMOOTH
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      if (anchor.getAttribute('class') && anchor.getAttribute('class').includes('anchor')) {
        anchor.addEventListener('click', function (e) {
          e.preventDefault();
          document.querySelector(this.getAttribute('href').replace('#/', '')).scrollIntoView({
            behavior: 'smooth'
          });
        });
      }
    });

  }, [])

  // set --vh var to css
  useVH()


  return (
    <SocketContext.Provider value={socket}>
      <div className="App">
        <Toaster position="bottom-right"
        />
        <HashRouter>
          <ScrollToTop />
          <Wrapper>
            <Routes>
              <Route path="/" element={<LandingPage />} />

              <Route path="/dimension/:slug" element={<Dimension />} />

              <Route path="/checkout/:planId" element={<CheckOutPage />} />

              <Route path="/authentification/*" element={<AuthentificationPage />} />

              <Route path="/features" element={<FeaturesPage />} />
              {/* <Route path="/pricing" element={<PricingPage />} /> */}
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/contact" element={<ContactPage />} />

              <Route path="/purchase-success" element={<PaymentSuccessPage />} />
              <Route path="/purchase-error" element={<PaymentErrorPage />} />
              <Route path="/email-verified" element={<EmailConfirmedPage />} />
              <Route path="/verify-email/:email" element={<EmailVerify />} />
              <Route path="/not-found" element={<NotFoundPage />} />

              <Route path="/privacy-policies" element={<Privacypolicy />} />
              <Route path="/terms-of-uses" element={<TermsOfUses />} />

              <Route
                path="*"
                element={<Navigate to="/not-found" replace />}
              />
            </Routes>
          </Wrapper>
        </HashRouter>
      </div>
    </SocketContext.Provider >

  );
}

export default App;
