import React, { useEffect, useState, useContext, useRef, useCallback, useMemo } from 'react'
import { useSearchParams, useNavigate, useParams } from "react-router-dom";

// THREE JS
import * as THREE from 'three';
import { UnrealBloomPass } from 'three-stdlib'
import { Canvas, extend, } from '@react-three/fiber'
import { PerspectiveCamera, Environment, Effects } from '@react-three/drei'

// API
import API from '../../api/index'

// Context
import { usePrompt } from '../../context/PromptContext'
import { SocketContext } from '../../context/socket';

// Custom Components
import PortalAnimated from './threejs/PortalAnimated'
import PortalGunAnimated from './threejs/PortalGunAnimated';
import FilesInterceptor from './threejs/FilesInterceptor';
import FileSender from './threejs/FileSender';
import { useDropzone } from 'react-dropzone';
import { useSwipeable } from 'react-swipeable'

extend({ UnrealBloomPass });

const baseStyle = {
    opacity: 0,
};

const focusedStyle = {
    opacity: 1,
    backgroundOpacity: .5,
    backgroundColor: '#00000080'
};

const acceptStyle = {
    opacity: 1,
    backgroundOpacity: .5,
    backgroundColor: '#00000080'
};

const rejectStyle = {
    opacity: 1,
    backgroundOpacity: .5,
    backgroundColor: '#00000080'
};

const Dimension = () => {

    //========== Custom Terminal functions
    const { setPrompt, clearTerminal, initDimensionTimer } = usePrompt()

    //========== Routes and Navigation
    let navigate = useNavigate();
    const { slug } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();

    //========== Socket from Context
    const socket = useContext(SocketContext);

    //========== Ref 
    // const orbitalControlRef = useRef()
    const fileSenderRef = useRef()
    const fileInputRef = useRef()
    const fileInterceptorRef = useRef()

    //========== States
    const [sessionAccess, setSessionAccess] = useState(null)
    const [planDetails, setPlanDetails] = useState(null)
    const [waitingFiles, setWaitingFiles] = useState([])

    //========== Variables (replace this with callback soon)
    const gunAnimationDelay = 2.6;

    const navFiles = (v) => fileInterceptorRef.current.navFiles(v)
    const dowloadFileHandler = () => fileInterceptorRef.current.dowloadFileHandler()

    // ======================================================================================
    // ========= Dimmension Connexion Related Functions

    // request to join session
    const requestJoinSession = useCallback((token) => {
        API.Session.requestJoin({ slug, tkn: token }).then(res => {

            const data = res && res.data ? res.data : null
            if (data && data.access && data.sessionId && data.plan) {

                // create session Access Object
                const sessionAccessObj = { sessionId: data.sessionId, userAccess: data.access }

                // save Guest or Host token in localstorage
                localStorage.setItem(data.access, token)

                // save Session details 
                data.plan.maxFileSizeMB = parseInt(data.plan.maxFileSizeMB)
                setPlanDetails(data.plan)
                setSessionAccess(sessionAccessObj)

                // set non downloaded Items if exist
                if (data.items) setWaitingFiles(data.items)

                // Remove token from url
                searchParams.delete('tkn')
                setSearchParams(searchParams)

                // emit session linked for host to connect
                socket.emit('session_linked', { slug: slug, tkn: token, sessionInitTokenExpireIn: data.plan.sessionInitTokenExpireIn }, (err) => {
                    if (err) alert(err)
                });

                // Init dimension timer ( && toggle remove menu header )
                initDimensionTimer(data.plan.sessionInitTokenExpireIn)

                // Clear terminal & set welcome to dimension message prompt
                clearTerminal()
                setPrompt([`Connected to dimension ${slug}`, 'Opening portal..'])

            } else {
                return navigate("/");
            }

        }).catch(err => {
            return navigate("/");
        })
    }, [clearTerminal, searchParams, setPrompt, setSearchParams, socket, slug, initDimensionTimer, navigate])

    // Look for token in LocalStorage (for host 1st connexion or any further re-connexion)
    const extractSessionTokenStorage = () => {

        // check storage
        const hostTknFromStorage = localStorage.getItem("hstTkn")
        const gestTknFromStorage = localStorage.getItem("gstTkn")

        if (hostTknFromStorage && gestTknFromStorage) return gestTknFromStorage
        else if (gestTknFromStorage) return gestTknFromStorage  // return gest token from Storage
        else if (hostTknFromStorage) return hostTknFromStorage  // return host token from Storage
        else return navigate("/");
        // no token in url and not oken in storage => redirect home

    }

    // Look for token in URL (for guest 1st connexion)
    const extractSessionTokenURL = useCallback(() => {
        const urlHasTkn = searchParams.has('tkn')
        const gestTknFromUrl = urlHasTkn ? searchParams.get('tkn') : null
        return gestTknFromUrl
    }, [searchParams])

    // Init dimension function
    const initDimension = useCallback(() => {

        const token = extractSessionTokenURL() ? extractSessionTokenURL() : extractSessionTokenStorage()

        if (token) {
            requestJoinSession(token)
        } else return navigate("/");

    }, [requestJoinSession, extractSessionTokenStorage, extractSessionTokenURL, navigate]);


    // ======================================================================================
    // ========= Files Gestion Related Functions

    // // Handle new file Input OnChang & call handleSubmit ( from Child FileSender )
    const handleChange = (file) => {
        fileSenderRef.current.handleChange(file)
    }


    // Handle Arrow Nav for Items
    const onKeyDownHandler = (event) => {
        const code = event ? event.code : null
        if (code && (code === "ArrowRight" || code === "ArrowLeft")) {
            const val = code === "ArrowRight" ? 1 : -1
            navFiles(val)
        } else if (code && code === "Enter") {
            dowloadFileHandler()
        }
    }

    const handlers = useSwipeable({
        onSwiped: (eventData) => {
            const code = eventData.dir
            if (code && (code === "Right" || code === "Left")) {
                const val = eventData.dir === "Right" ? -1 : 1
                navFiles(val)
            }
        }
    });


    // ======================================================================================
    // ========= UseEffects

    // Launch dimention init on landing
    useEffect(() => {
        if (!sessionAccess) {
            initDimension();
        }
    }, [sessionAccess, initDimension])

    // Socket.io Setup session_timeout
    useEffect(() => {
        if (sessionAccess) {
            socket.removeAllListeners("session_timeout");
            socket.on('session_timeout', (data, err) => {
                setPrompt([`Dimension ${slug} expired, disconnecting..`])
                return navigate("/");
            });
        }
    }, [sessionAccess])


    // ======================================================================================
    // ========= Dom Components 

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles && acceptedFiles[0]) handleChange(acceptedFiles[0])
    }, [])



    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: onDrop,
        // accept: { 'image/*': [] },
        maxFiles: 1,
        noClick: true,
        noKeyboard: true
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return <div {...getRootProps()} tabIndex={0} onKeyDown={onKeyDownHandler} {...handlers} className='flex w-full h-screen h-full outline-none' >
        <input {...getInputProps()} />

        {/* HIDDEN FORM (file input) */}
        <form style={{ display: 'none' }}>
            <input onChange={(event) => handleChange(event.target.files[0])} ref={fileInputRef} type="file" />
            <button className='bg-white'>Submit</button>
        </form>

        <div {...getRootProps({ style })} className='flex w-full h-full absolute pointer-events-none p-12 z-20 opacityFadeOut duration-1000'>
            <div style={{ strokeDasharray: 4, }} className=' flex-1 flex justify-center items-center relative'>
                <h1 className='text-7xl font-semibold text-white'>Drop your files<br />anywhere</h1>
                <div className='h-24 w-24 bg-transparent absolute top-0 right-0 border-t-8 border-r-8 border-white rounded-tr-2xl'></div>
                <div className='h-24 w-24 bg-transparent absolute top-0 left-0 border-t-8 border-l-8 border-white rounded-tl-2xl'></div>
                <div className='h-24 w-24 bg-transparent absolute bottom-0 right-0 border-b-8 border-r-8 border-white rounded-br-2xl'></div>
                <div className='h-24 w-24 bg-transparent absolute bottom-0 left-0 border-b-8 border-l-8 border-white rounded-bl-2xl'></div>
            </div>
        </div>

        {/* THREE JS CANVAS */}
        <Canvas>

            {/* =============================================== Ligth ==========================================*/}
            <ambientLight intensity={0.3} />
            <spotLight intensity={.5} castShadow color={"#FFF"} penumbra={1} position={[-5, 2, -10]} />
            <spotLight intensity={1} castShadow color={"#FFF"} penumbra={1} position={[5, 20, 10]} />
            <spotLight intensity={1} castShadow color={"#FFF"} penumbra={1} position={[-5, 25, -8]} />

            {/* =============================================== Light Effects UnrealBloomPass ==========================================*/}
            <Effects disableGamma>
                <unrealBloomPass threshold={.1} strength={1.5} radius={.1} />
            </Effects>

            {/* =============================================== Environment ( to setup.. ) ==========================================*/}
            <Environment background>
                <mesh scale={100}>
                    <sphereGeometry />
                    <meshBasicMaterial color={"#000"} side={THREE.BackSide} />
                </mesh>
            </Environment>

            {/* =============================================== Camera ==========================================*/}
            <PerspectiveCamera makeDefault position={[0, 4, 20]} />

            {sessionAccess &&
                <CanvasContent
                    sessionAccess={sessionAccess}
                    planDetails={planDetails}
                    fileSenderRef={fileSenderRef}
                    socket={socket}
                    setPrompt={setPrompt}
                    gunAnimationDelay={gunAnimationDelay}
                    waitingFiles={waitingFiles}
                    fileInterceptorRef={fileInterceptorRef}
                    fileInputRef={fileInputRef}
                />
            }


        </Canvas>
    </div >
}

export const CanvasContent = (props) => {

    const { sessionAccess, planDetails, fileSenderRef, socket, setPrompt, gunAnimationDelay, waitingFiles, fileInterceptorRef, fileInputRef } = props

    // Increment used MO to follow plan limit
    const incrementUsedMO = (f) => {
        fileSenderRef.current.incrementUsedMO(f)
    }

    // Toggle file Input with Ref
    const handlePortalClick = () => {
        if (fileInputRef.current)
            fileInputRef.current.click()
    }


    return <group>

        {/* =============================================== File Uploading and Jumping in Portal ==========================================*/}
        <FileSender planDetails={planDetails} ref={fileSenderRef} sessionAccess={sessionAccess} socket={socket} setPrompt={setPrompt} />

        {/* =============================================== Portal Gun Animated ==========================================*/}
        <PortalGunAnimated animateOn position={[1, 2.2, 15]} />


        {/* =============================================== Portal & File Interceptor GROUP ==========================================*/}
        <group position={[0, 7, -5]}>
            <mesh onPointerOver={() => document.body.style.cursor = 'pointer'}
                onPointerOut={() => document.body.style.cursor = 'auto'} onClick={handlePortalClick} >
                <PortalAnimated setPrompt={setPrompt} delay={gunAnimationDelay} />
            </mesh>
            <mesh>
                <FilesInterceptor incrementUsedMO={incrementUsedMO} waitingFiles={waitingFiles} ref={fileInterceptorRef} setPrompt={setPrompt} socket={socket} sessionAccess={sessionAccess} />
            </mesh>
        </group>

    </group>
}

export default Dimension;
